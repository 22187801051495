.music-option {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
}

.music-option > img {
    width: 20px;
    height: 20px;

    margin-top: 3px;
    margin-right: 15px;
}

.friend-page-age-selector-text {
    margin-top: 30px;
}

#friend-page-age-selector-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0px;
}

#friend-page-age-range-wrapper {
    margin-left: 5%;
    max-height: none;
    padding-bottom: 20px;
    color: #1E2738;
    letter-spacing: 1px;
    font-weight: bold;
}

#friend-page-age-range-wrapper p {
    font-size: 24px;
}

@media only screen and (max-width: 700px) {
    #friend-page-age-range-wrapper {
        flex-direction: column;
    }
}

@media only screen and (min-width: 700px) {
    #friend-page-age-range-wrapper {
        align-items: center;
    }
}

#friend-page-age-range-wrapper.settings {
    align-items: center;
}

@media only screen and (max-width: 700px) {
    #friend-page-age-selector-wrapper {
        padding-top: 10px;
    }

    .friend-age-lower {
        margin-left: 0;
    }
}


.friend-page-music-interest-wrapper {
    margin-left: 5%;
}