#friend-content {
    height: auto;

    padding-top: 50px;
    padding-bottom: 100px;

    background-color: #404F8E;
    min-height: calc(100vh - 100px);
}

@media only screen and (min-width: 1300px) {
    #friend-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #friend-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #friend-content {
        padding-bottom: 40px;
        width: calc(100% - 50px);
    }
}

@media only screen and (max-width: 896px) {
    #friend-content {
        padding-top: 0;
    }
}

#friend-content-alert-wrapper {
    margin-left: 40px;
    margin-top: 0px;
    margin-bottom: 35px;
    margin-right: 40px;

    padding: 14px;

    letter-spacing: 1.5px;

    background-color: #F17432;

    color: white;
    display: flex;

    align-items: center;

    /* border-width: 2px;
    border-style: solid;

    margin-top: 10px;
    margin-bottom: 15px;

    padding-top: 15px;
    padding-bottom: 15px;

    border-color: #F17432;
    background-color: #F17432;

    color: white;
    display: flex;

    align-items: center; */
}

/* @media only screen and (min-width: 700px) {
    #friend-content-alert-wrapper {
        width: 100%;
    }
} */

#friend-content-alert-wrapper.info {
    border-color: #F17432;
    background-color: #F17432;
}

#friend-content-alert-message {
    width: auto;
}

@media only screen and (max-width: 700px) {
    #friend-content-alert-message {
        width: 180px;
    }
}

#friend-content-alert-icon {
    min-width: 30px;
    max-width: 30px;
    height: 30px;

    font-weight: bold;
    text-align: center;

    border-radius: 50%;
    border-style: solid;
    border-color: white;

    margin-left: 15px;
    margin-right: 10px;
}

@media only screen and (max-width: 700px) {
    #friend-content-alert-icon {
        margin-left: 10px;
    }
}

#friend-content-wrapper {
    border: 0px solid #A4F2F4;
    background-color: white;
    border-radius: 0px;
    padding: 23px;
    /* margin-top: 80px; */
    color: #1E2738;
}

#shareheader {
    background-color: #1E2738;
    font-weight: bold;
    color: #9FC6CC;
    text-align: center;
    padding: 20px;
    font-size: 40px;
}

.orange {
    color: #F17432;
}

#questionbody h1{
    padding: 30px 0px 30px 0;
    color: #1E2738;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 1px;
    line-height: 35px;
    margin-left: 8%;
    width: 84%;
}

#questionbutton button {
    color: white;
    font-family: Helvetica;
    background: #9FC6CC;
    border: 0px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

#last {
    margin-left: 8%;
    display: inline;
}

#next {
    justify-self: right;
    margin-left: 49%;
    display: inline;
}

@media only screen and (max-width: 896px) {
    #friend-content-wrapper {
        width: 90%;

        padding-top: 0;
        padding-bottom: 10px;

        min-height: unset;
    }

    #friend-content-wrapper.settings {
        margin-top: 50px;
    }

    #next {
        margin-left: 41%;
    }
}

@media only screen and (max-width: 850px) {
    #next {
        margin-left: 40%;
    }
}

@media only screen and (max-width: 800px) {
    #next {
        margin-left: 35%;
    }
}

@media only screen and (max-width: 750px) {
    #next {
        margin-left: 34%;
    }
}

@media only screen and (max-width: 700px) {
    #next {
        margin-left: 30%;
    }
}

@media only screen and (max-width: 650px) {
    #next {
        margin-left: 25%;
    }
}

@media only screen and (max-width: 600px) {
    #next {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 550px) {
    #last {
        margin-left: 27%;
    }

    #next {
        margin-left: 27%;
    }
}

#friend-content-header {
    font-size: 35px;
    font-weight: 300;
    padding-bottom: 20px;
}

@media only screen and (max-width: 896px) {
    #friend-content-header {
        font-size: 25px;
    }
}

#friend-content-header-wrapper {
    padding-top: 20px;
}

#friend-content-title {
    padding-top: 10px;
    padding-bottom: 10px;

    color: #A4F2F4;
    font-weight: bold;

    font-size: 20px;
    letter-spacing: 5px;
}

@media only screen and (max-width: 700px) {
    #friend-content-title {
        font-size: 14px;
        letter-spacing: 2px;
    }
}

#friend-content-directions {
    font-size: 18px;
    line-height: 28px;

    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (max-width: 896px) {
    #friend-content-directions {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#friend-action-btns {
    padding-top: 20px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 896px) {
    #friend-action-btns {
        width: unset;

        padding-top: 10px;
        padding-bottom: 0;

        margin-bottom: 20px;
    }
}

#friend-action-go-back {
    border: none;

    padding-left: 40px;
    padding-right: 40px;

    background-color: #684CB5;
}

@media only screen and (max-width: 896px) {
    #friend-action-go-back {
        width: 110px;

        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 430px) {
    #friend-action-go-back {
        margin-right: 10px;
    }
}

#friend-action-continue {
    border: none;
    border-radius: 0;
    margin-left: auto;

    padding-left: 40px;
    padding-right: 40px;
    margin-right: 5%;
    margin-bottom: 3%;

    background-color: #F17432;

    letter-spacing: 1px;
    font-weight: bold;
}

@media only screen and (max-width: 896px) {
    #friend-action-continue {
        width: 110px;

        padding-left: 10px;
        padding-right: 10px;
    }

    #friend-action-continue.settings {
        font-size: 1rem;
        margin-right: auto;

        padding-left: 100px;
        padding-right: 100px;
    }
}
