.container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    padding-top: .6rem;
    padding-bottom: .6rem;
}

.container-tbody > tr > th {
    vertical-align: middle;
}

#modalBody {
    overflow-x:auto;
    font-size: 14px;
    min-width: calc(100% - 60px);

    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 25px;
}

@media only screen and (max-width: 896px) {
    #modalBody {
        margin-left: 15px;
        margin-right: 14px;
        margin-bottom: 15px;
    }
}

#modalBody::-webkit-scrollbar {
    width: 15px;
    background-color: #5062A3;
}

#modalBody::-webkit-scrollbar-thumb {
    background-color: #0D184C;
}

#modalBody > table {
    border: none;
    margin-bottom: 5px;
    border-collapse: collapse;
}

#modalBody th,
#modalBody td {
    border: none;
    min-width: 30px;

    padding-left: 0;
    padding-right: 0;
}

#modalBody th {
    color: white;
}

#modalBody td > img {
    cursor: pointer;
}

#modalBody thead > tr > th {
    padding-bottom: 0;
}

#modalBody th > span {
    width: 30px;
    font-weight: 500;

    text-align: center;
    display: inline-block;
}

#modalBody th > span.time {
    font-size: 18px;
}

#modalBody th > span.hidden {
    visibility: hidden;
}

.fixed-col {
    z-index: 5;

    height: 45px;
    width: 20px;

    color: white;
    position: fixed;

    font-size: 18px;
    font-weight: 500;
    text-align: center;

    padding: 10px 40px 10px 5px !important;
}

@media only screen and (max-width: 896px) {
    .fixed-col {
        left: 11px;
    }
}

#day0 td,
#day0 .fixed-col,
#day2 td,
#day2 .fixed-col,
#day4 td,
#day4 .fixed-col,
#day6 td,
#day6 .fixed-col {
    background-color: #262F6D;
}

#day1 td,
#day1 .fixed-col,
#day3 td,
#day3 .fixed-col,
#day5 td,
#day5 .fixed-col {
    background-color: #404F8E;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: blue;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 12px;
    top: 8px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.talk-schedule-header {
    background-color: #1E2738;
    padding: 30px 0;
}

.talk-schedule-header h1 {
    font-size: 30px !important;
    text-transform: uppercase;
    font-weight: 700 !important;
    margin: 0;
    color: #9FC6CC;
}

#talkSchedule {
    margin-top: 0;
    top: 100px;

    max-width: unset;
    width: 800px;

    background-color: white;
}

@media only screen and (max-width: 896px) {
    #talkSchedule {
        width: calc(100% - 60px);

        margin-left: 30px;
        margin-right: 30px;
    }
}

#talkSchedule > .modal-content {
    height: 100%;

    margin-top: 15px;
    margin-right: 15px;

    border: none;
    background-color: unset;
}

@media only screen and (max-width: 896px) {
    #talkSchedule > .modal-content {
        margin-top: 10px;
        margin-right: 10px;
    }

    #talkSchedule > .modal-content > #close-modal {
        margin-right: 10px;
    }
}

#talkSchedule h1,
#talkSchedule h2 {
    font-weight: 300;
    text-align: center;
}

#talkSchedule h1.chat-modal {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 35px;
}

#talkSchedule h1 {
    margin-top: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.5px;
}

.btn.reschedule-option {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    background: transparent;
    border: solid 1px black;
    font-weight: 0 !important;
}

@media only screen and (max-width: 896px) {
    .btn.reschedule-option {
        max-width: 220px;
        min-width: 100px;
    }
}

#talkSchedule h2 {
    margin-top: 20px;
    margin-bottom: 20px;

    font-size: 20px;
}

@media only screen and (max-width: 896px) {
    #talkSchedule h1 {
        font-size: 25px;
    }

    #talkSchedule h2 {
        font-size: 20px;
    }
}

#request-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 30px 30px;
}

@media only screen and (max-width: 896px) {
    #request-options {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 25px;
    }
}

#request-options > div {
    color: white;
    letter-spacing: 1px;
}

.request-options-talk-request {
    flex-direction: row !important;
    padding: 0 5% !important;
    margin-bottom: 40px;
}

.request-options-talk-request .btn {
    background-color: #F17432 !important;
    font-weight: bold;
}

#no-confirm {
    margin-left: 10px;
    margin-right: 10px;
}

#no-confirm,
#request-options > div.btn,
.btn.reschedule-option {
    width: 160px;
    background-color: transparent;
}

#request-options > div.btn#block-user {
    background-color: #262F6D;
    border: 3px solid #A4F2F4;
    border-radius: 10px;
}

#tz-compat {
    color: white;
    text-align: center;
    margin-bottom: 15px;
}

.modalArrow {
    font-size: 200%;
}

.modalArrow:hover {
    cursor: pointer;
}

#expired-times {
    padding-top: 30px;
    padding-bottom: 40px;
}

#possible-times {
    display: flex;
    flex-direction: column;

    max-height: 250px;

    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 896px) {
    #possible-times {
        margin: 10px 20px 20px;
    }
}

@media only screen and (min-width: 896px) {
    #possible-times {
        margin: 20px 75px 30px;
    }
}

#possible-times::-webkit-scrollbar {
    width: 15px;
    background-color: #5062A3;
}

#possible-times::-webkit-scrollbar-thumb {
    background-color: #0D184C;
}

.possible-time-row {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 896px) {
    .possible-time-row {
        min-height: 50px;
        flex-direction: column;
    }
}

.possible-time-option {
    color: white;
    display: flex;

    cursor: pointer;

    width: 180px;
    align-items: center;
}

.possible-time-option > img {
    margin-right: 10px;
}

#expired-times {
    color: white;
    font-size: 18px;
    text-align: center;
}

@media only screen and (max-width: 896px) {
    #block-user,
    #no-confirm,
    #confirm-times {
        width: 250px !important;
        margin-bottom: 15px;
    }
}

#confirm-times.no-confirm-times {
    cursor: default !important;
    background-color: grey !important;
}

.schedule-body {
    margin: 5%;
}

.schedule-body p {
    font-size: 14px !important;
}

.schedule-body-dropdown {
    width: 50%;
}

.schedule-body-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transform: translateX(-5px);
}

.time-box {
    margin: 5px;
    padding: 10px;
    width: 120px;
    height: max-content;
    letter-spacing: 1.5px;
    font-size: 20px;
    text-align: center;
    color: white;
    background-color: #9FC6CC;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
}

.request-talk {
    margin-top: 20px;
}