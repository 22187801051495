#logo-header {
    width: 275px;
    background: #1E2738;
    /* margin-left: 4rem; */
    margin-top: -15px;
}

.hamburger {
    cursor: pointer;

    padding: 20px 18px 15px 18px;
    display: inline-block;

    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;

    border: 0;
    margin: 0;

    overflow: visible;
}

.hamburger:focus {
    outline: none;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #1E2738;
}

.hamburger-box {
    width: 30px;
    height: 24px;

    margin-top: 15px;
    position: relative;
    display: inline-block;
}

@media only screen and (min-width: 700px) {
    .hamburger-box {
        margin-top: 7px;
    }
}

.hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px;

    transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    -webkit-transition-duration: 0.25s;
}

.hamburger-inner, .hamburger-box::before, .hamburger-box::after {
    width: 30px;
    height: 4px;

    border-radius: 4px;
    position: absolute;
    background-color: #1E2738;

    transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -webkit-transition-timing-function: ease;
}

.hamburger-box::before, .hamburger-box::after {
    content: "";
    display: block;

    transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;

    transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;

    transition-property: transform;
    -moz-transition-property: transform;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
}

.hamburger-box::before {
    top: -1px;
}

.hamburger-box::after {
    bottom: -1px;
}

.hamburger--squeeze.is-active .hamburger-box::before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    top: -2px;
    width: 34px;
}

.hamburger--squeeze.is-active .hamburger-inner {
    opacity: 0;

    transition-property: opacity;
    -moz-transition-property: opacity;
    -webkit-transition-property: opacity;
}

.hamburger--squeeze.is-active .hamburger-box::after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    bottom: -2px;
    width: 34px;
}

#menu {
    background-color: rgba(0, 0, 0, 0.75);
    margin-top: 30px;
}

/* @media only screen and (min-width: 1170px) {
    #menu {
        display: none !important;
        max-height: 0 !important;
    }
} */

#menu-btn {
    display: none;
    /* margin-right: 3rem; */
}

/* @media only screen and (max-width: 1170px) {
    #menu-btn {
        display: inline-block;
    }
} */

.nav-menu-item.header {
    padding: 10px 20px 10px 60px;
}

.nav-menu-item.header.events {
    padding-top: 20px;
}

.nav-menu-item.header.logout {
    padding-bottom: 20px;
}

/* #menu-events-tab {
    margin: 20px 10px 10px 20px;
}

#menu-login-tab {
    margin: 10px 10px 10px 20px;
}


#menu-signup-tab {
    margin: 10px 10px 20px 20px;
} */

.btn.loginbutton {
    padding-left: 13px;
    padding-right: 13px;
}

/* #nav-item-wrapper {
    position: absolute;
    right: 3%;
    letter-spacing: 2px;
} */

#nav-item-wrapper a {
    color: #1E2738;
    user-select: none;
}

#nav-item-wrapper a:hover {
    color: #A6A7B1;
}

/* .tab {
    margin-right: 35px;
} */

/* @media only screen and (max-width: 1170px) {
    #nav-item-wrapper {
        display: none;
    }
} */

/* #menu #loginButton,
#nav-item-wrapper #loginButton {
    border: 2px solid #B377D8;
} */

#menu #loginButton:hover,
#nav-item-wrapper #loginButton:hover {
    /* border-width: 3px; */
    /* cursor: pointer; */
}
