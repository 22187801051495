#cookies-container {
    bottom: 0;
    position: fixed;

    width: 100%;
    height: 50px;

    color: white;
    text-align: center;

    padding: 12px;
    background-color: #2d3d8e;
}

@media only screen and (max-width: 1400px) {
    #accept-message {
        width: 90%;
    }

    #cookies-container {
        height: 75px;
    }
}

@media only screen and (max-width: 750px) {
    #cookies-container {
        height: 100px;
    }
}

@media only screen and (max-width: 530px) {
    #cookies-container {
        height: 125px;
    }
}

@media only screen and (max-width: 530px) {
    #cookies-container {
        height: 150px;
    }
}

#cookies-container #accept-message {
    display: inline-block;
}

#cookies-container #privacy-link {
    text-decoration: underline;
}

#cookies-container #accept-button {
    float: right;
    display: block;
    cursor: pointer;

    margin-left: 10px;
}
