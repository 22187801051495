
/*.form-signin {*/
  /*max-width: 330px;*/
  /*padding: 15px;*/
  /*margin: 0 auto;*/
/*}*/
/*.form-signin .form-signin-heading,*/
/*.form-signin .checkbox {*/
  /*margin-bottom: 10px;*/
/*}*/
/*.form-signin .checkbox {*/
  /*font-weight: normal;*/
/*}*/
/*.form-signin .form-control {*/
  /*position: relative;*/
  /*height: auto;*/
  /*-webkit-box-sizing: border-box;*/
          /*box-sizing: border-box;*/
  /*padding: 10px;*/
  /*font-size: 16px;*/
/*}*/
/*.form-signin .form-control:focus {*/
  /*z-index: 2;*/
/*}*/
/*.form-signin input[type="email"] {*/
  /*margin-bottom: -1px;*/
  /*border-bottom-right-radius: 0;*/
  /*border-bottom-left-radius: 0;*/
/*}*/
/*.form-signin input[type="password"] {*/
  /*margin-bottom: 10px;*/
  /*border-top-left-radius: 0;*/
  /*border-top-right-radius: 0;*/
/*}*/
/*.form-signin p {*/
  /*margin-top: 10px;*/
/*}*/

.primary-wrapper {
    /*margin-top: 40px;*/
    border-radius: 4px;
    /* border: solid 6px #00D96C; */
    min-height: 250px;
    /* background-color: #6F00FF; */
}

@media only screen and (min-width: 896px) {
    .primary-wrapper {
        padding: 30px 70px;
    }
}

@media only screen and (max-width: 896px) {
    .primary-wrapper {
        padding: 15px;
    }
}

.compact {
    width: 400px;
}

.extended {
    width: 925px;
    max-width: 100%;
}

.reg-complete button {
  box-shadow: 0.375rem 0.375rem 1rem 0 rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 5px 5px 5px 5px;
  border: 2px solid #fff;
  word-break: initial;
  height: 50px;
  cursor: pointer;
  outline: none;
}

.reg-complete p {
  color: white;
}

.extra-extended {
    width: 900px;
    max-width: 100%;
}

@media only screen and (max-width: 450px) {
    .primary-wrapper {
        border: none;
    }
}
