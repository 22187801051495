#footer-links-container {
    display: flex;
}

@media only screen and (max-width: 1024px) {
    #footer-links-container {
        align-items: center;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 1024px) {
    #footer-links-container {
        width: 30%;
    }

    .not-rightmost-footer-link {
        margin-right: 10%;
        display: table;
    }
}

#footer-copyright-container {
    text-align: center;
}

#footer-copyright {
    color: grey;
    text-align: center;
}

#footer-content-desktop-container {
    display: flex;
    padding-top: 8px;
    justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
    #footer-content-desktop-container {
        display: none;
    }
}

.footer {
    min-height: 60px;
    width: 100%;
    background-color: rgb(238, 243, 248);
    bottom: 0;
    padding: 0.5rem 1.5rem;
}

.footer a {
    color: #1E2738;
}

.footer.no-ok {
    min-height: 110px;
}

@media only screen and (max-width: 1400px) {
    .footer.no-ok {
        min-height: 135px;
    }
}

@media only screen and (max-width: 750px) {
    .footer.no-ok {
        min-height: 160px;
    }
}

@media only screen and (max-width: 530px) {
    .footer.no-ok {
        min-height: 185px;
    }
}

@media only screen and (max-width: 400px) {
    .footer.no-ok {
        min-height: 210px;
    }
}

@media only screen and (min-width: 897px) {
    .footer a {
        white-space: nowrap;
    }
}

.footer a:hover {
    color: #A6A7B1;
}

@media only screen and (min-width: 1024px) {
    .footer .mobile-screen-only {
        display: none !important;
    }
}

@media only screen and (max-width: 430px) {
    #footer-links-container.mobile-screen-only {
        font-size: 15px;
    }

    #footer-copyright-container.mobile-screen-only {
        font-size: 14px;
    }
}
