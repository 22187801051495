.available-times-header > h5 {
    color: black;
    margin-left: 30%;
}

.flat-input {
    height: 40px;
    padding-left: 10px;
    color: #424242;
    border: 1px solid black;
    /* border-radius: 4px; */
    font: inherit;
    /* border: 2px solid var(--primary-color); */
    /*background: rgba(0,0,0,.05);*/
    /* margin: 12px 0; */
    width: 100%;
    -webkit-appearance: none;
    -webkit-border-radius:0;
    border-radius:0;
}

.flat-textarea {
    /* width: -moz-available;
    width: -webkit-fill-available; */
    letter-spacing: 1px;
    overflow-y: auto;
    height: 180px;
    font: inherit;
    padding: 10px;
    resize: none;
    -webkit-appearance: none;
    border: 1px solid #1E2738;
    font-size: 16px;
    line-height: 20px;
    width: 84%;
    margin-left: 8%;
    margin-top: 0px;
    margin-bottom: 30px;
}

.half {
    width: calc(50% - 10px);
}

.inline {
    display: inline-flex;
    margin: 0 10px;
    width: fit-content;
}

@media only screen and (max-width: 700px) {
    .inline > select {
        width: 20px;
    }
}

@media only screen and (min-width: 700px) {
    .inline > select {
        width: auto;
    }

    .inline.gender > select {
        width: 125px;
    }
}

input:focus, textarea:focus, select:focus {
    outline: none !important;
}

.btn {
    height: 40px;
    width: fit-content;
    width: intrinsic;
    border-radius: 0px;
    cursor: pointer;
    padding: 0 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.large {
    font-size: 18px;
    padding: 0 20px;
}

.x-large {
    font-size: 35px;
    padding: 30px 20px;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active {
    border: 2px solid;
    color: var(--primary-text) !important;
    border-color: var(--primary-text) !important;
    background-color: #fff !important;
}

.btn-outline-grey {
    border: 2px solid #757575;
    color: #757575;
    background: #fff;
}

.btn-outline-grey:hover {
    background: #757575;
    color: #fff;
}

.btn-primary, .btn-primary:hover, .btn-outline-primary:active  {
    background-color: var(--primary-color) !important;
    color: var(--primary-text) !important;
    border-color: inherit !important;
    border: none;
}

.btn-alert {
    border: 2px solid;
    width: 40px;
    margin: 10px;
    border-radius: 50%;
    font-weight: bold;
}

.btn-disk {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.select-wrapper {
    /*width: fit-content;*/
    position: relative;

}

.flat-select {
    font: inherit;
    color: #1E2738;
    padding: 6px 0 7px 7px;
    display: block;
    /* height: 40px; */
    box-sizing: border-box;
    /* border-radius: 4px; */
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    min-width: 100px;
    padding-right: 32px;
    overflow: hidden;
    min-height: 1.1875em;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    /* border: 2px solid var(--primary-color); */
    border: none;
    width: fit-content;
    /* font-size: 16px; */
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    width: -moz-available;
    width: -webkit-fill-available;
}

.flat-select > option {
    color: black;
}

.arrow {
    top: calc(50% - 12px);
    right: 0;
    height: 24px;
    position: absolute;
    fill: #1E2738;
    pointer-events: none;
    margin-right: 5px;
}

.alert {
    color: rgb(255, 154, 6);
    background: rgba(255, 183, 77, .6);
    border-radius: 6px;
    max-width: 100%;
}

.success {
    color: black;
    background: rgb(7, 165, 68);
    border-radius: 6px;
    max-width: 100%;
}

#menu-icon {
    height: 90%;
    width: 90% !important;
}

.drawer {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #000;
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: var(--primary-color);
}

.area-tab-box, .user-tab-box {
    background-color: #F17432;
    color: #1E2738;

    border: 5px solid;
    border-radius: 50%;

    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
}

.area-tab-box:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);

    -webkit-transition: transform .25s ease-in-out;
    -moz-transition:transform .25s ease-in-out;
    -ms-transition:transform .25s ease-in-out;
}

.event-card {
    margin: 20px 15px 0 15px;
    padding: 15px 15px 15px 15px;

    width: 700px;
    max-width: 100%;
    cursor: pointer;
    /* background: #9FC6CC; */
    background: #9FC6CC;
}

.no-event.event-card {
    cursor: default;
}

@media only screen and (max-width: 896px) {
    .event-card {
        margin-left: 0;
        margin-right: 0;
    }
}

.dropdown-arrow {
    width: 22px;
    height: 8px;
}

.event-card.la {
    background: #FF2025;
}

.event-card.sf {
    background: #FF9966
}

.event-card.ny {
    background: #00D96C
}

.event-card.tky {
    background: #6F00FF
}

.outline {
    border: 3px solid #FF8FAC;
    /* border: 3px solid #FF6990; */
}

.event-card.pref {
    padding: 15px;
    max-width: 100%;
    background: var(--primary-color);
    color: #000;
    cursor: pointer;
    margin: 30px 0 15px 0;
    border-radius: 4px;
    box-shadow: 0.375rem 0.375rem 1rem 0 rgba(0, 0, 0, 0.3);
}

.event-card.pref.outline {
    border: none
}

.event-info {
    border-style: solid;
    border-width: 0 2px 2px 2px;

    -webkit-transition: max-height .5s ease-out;
    transition: max-height .5s ease-out;

    word-break: break-word;
    overflow: hidden;
    padding: 0 15px;
    max-height: 0;
    color: #1E2738;
}

@media only screen and (max-width: 896px) {
    .event-info {
        border-width: 0 2px 2px 2px;
        padding: 0;
    }
}

.event-info .details {
    color: #1E2738;
    font-weight: bold;
}

.event-info button {
    color: white;
    border-radius: 5px 5px 5px 5px;
    border: 2px solid #fff;
    word-break: initial;
    min-height: 30px;
    cursor: pointer;
    outline: none;
}

.event-info button:hover {
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);

    -webkit-transition: transform .25s ease-in-out;
    -moz-transition:transform .25s ease-in-out;
    -ms-transition:transform .25s ease-in-out;
}

.event-info button.la {
    background-color: #FF2025 !important;
}

.event-info button.sf {
    background-color: #FF9966 !important;
}

.event-info button.ny {
    background-color: #00D96C !important;
}

.event-info button.tky {
    background-color: #6F00FF !important;
}

.copyButton {
    width: 10.0rem;
}

.place-name {
    width: 200px;
    word-break: break-word;
}

.place-tab {
    width: 125px;
    margin: 10px 15px 10px 15px;
}

@media only screen and (max-width: 896px) {
    .place-tab {
        width: 115px;
    }
}

.react-datepicker__input-container {
    position: relative;
    display: block;
}

.pending-name {
    color: black
}

.pending-name > h5 {
    color: black;
    margin-left: 30%;
}

.result-card {
    border-bottom: 1px solid;
    border-color: white;
    color: white;

    padding-bottom: 40px;
    padding-top: 40px;
}

.search-action:hover {
    cursor: pointer;
}

.dismiss-alert:hover {
    cursor: pointer;
}

.view-profile {
    border: 2px solid #62B5B5;
    border-radius: 5px;

    padding: 5px 15px 5px 15px;
}

.view-profile:hover {
    background-color: #262F6D;
}

.modal-content {
    margin-top: 80px;
}

.search-button {
    height: 40px;
}

#result-section {
    border-left: 2px solid var(--primary-color);
    overflow-y: auto;
    position: relative;
}

#back-to-filters {
    position: fixed;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0,0,0,.3);
    color: #fff;
    display: none;
    transition: background-color .35s ease;
}

#back-to-filters:hover {
    background-color: rgba(0,0,0,.4);
}

.profile-question {
    margin-top: 0px;
    margin-left: 5px;
    margin-bottom: 30px;
    width: fit-content;
}

.profile-photo {
    overflow: hidden;
    margin: 10px;
}

@media only screen and (min-width: 896px) {
    .profile-photo {
        height: 220px;
        width: 200px;
    }
}

@media only screen and (max-width: 896px) {
    .profile-photo {
        height: 105px;
        width: 105px;
    }
}

.profile-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.header-menus {
    /*min-height: fit-content;*/
    max-height: 300px;
    width: 250px;
    padding: 10px;
    border-color: var(--primary-color);
}

.talk-request-card {
    width: 100%;
    border: 2px solid var(--primary-color);
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.talk-request-card.sf {
    background-color: #FF9966;
}

.talk-request-card.la {
    background-color: #FF2025;
}

.talk-request-card.ny {
    background-color: #00D96C;
}

.talk-request-card.tky {
    background-color: #6F00FF;
}

.outline-box {
    padding: 20px;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
}

.hover-x:hover {
    cursor: pointer;
}

.outline-box.ny:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

    -webkit-transition: transform .25s ease-in-out;
    -moz-transition:transform .25s ease-in-out;
    -ms-transition:transform .25s ease-in-out;
}

.outline-box.ny {
    background-color: #00D96C;
    box-shadow: 0.375rem 0.375rem 1rem 0 rgba(0, 0, 0, 0.3);
}

.outline-bottom {
    border-bottom: 2px solid var(--primary-color);
}

#posts {

}

#board {

}

#submitPost {

}

.native-select > select {
    border-color: var(--primary-color);
    color: #424242;
}

@media only screen and (max-width: 896px) {
    .native-select > select {
        width: 100%;
    }
}

@media only screen and (min-width: 896px) {
    .native-select > select {
        width: 300px;
    }
}

.native-select > svg {
    fill: #424242;
}

.nav-menu-overlay {
    background: #EEF3F8;
    color: #fff;

    height: fit-content;
    max-height: 0;

    overflow: hidden;
    width: 250px;

    position: absolute;
    z-index: 1;

    top: 60px;
    right: 0;
}

.nav-menu-item {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px !important;
    display: flex;
    align-items: center;
    -webkit-transition: background .35s ease;
    transition: background .35s ease;
    cursor: pointer;
    /*color: #e9e9e9;*/
}

.lrg-icon {
    width: 30px;
    height: 30px;
}

.sub-group {
    width: 80%;
    margin-left: auto;
}

.nav-menu-item:hover,
.nav-menu-item:active,
.nav-menu-item:focus {
    background: rgba(255,255,255,.5);
}

button:disabled {
    background-color: lightgrey !important;
    opacity: .80 !important;
    cursor: not-allowed !important;
}

button:disabled:hover {
    background-color: lightgrey !important;
    opacity: .80 !important;
    cursor: not-allowed !important;
}

.multiple-answers {
    display: flex;
    flex-wrap: wrap;
}

.mc-answer {
    display: flex;
    min-width: 50%;
}

.mc-answer > img {
    width: 15px;
    height: 15px;

    margin-top: 5px;
    margin-right: 13px;
}

.profile-name {
    border-width: 2px;
    border-radius: 8px;
    border-style: solid;

    background-color: #262F6D;
}

@media only screen and (min-width: 896px) {
    .profile-name {
        width: 540px;

        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 896px) {
    #result-section {
        border-left: none;
    }

    #back-to-filters {
        display: flex;
    }
    .mc-answer {
        min-width: 100% !important;
    }
}

@media only screen and (max-width: 420px) {
    /*.header-menus {*/
        /*width: 180px !important;*/
        /*margin-left: 60px !important;*/
    /*}*/
}

@media only screen and (max-height: 620px) {
    /* #callerVideo {
        height: 280px;
    } */

}

.no-hover, .no-hover:hover {
    font-size: 18px;
    border: none;
}

#eventsButton {
    border-color: #E1C87F;
    border-style: solid;
    border-width: 2px;
    font-size: 15px;
}

#eventsButton:hover {
    border-width: 3px;
    cursor: pointer;
}

#signupAd {
    font-size: 18px;
    letter-spacing: 1.5px;
    font-weight: bold;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
    width: 250px;
    color: white;
    background-color: #9FC6CC;
    margin: auto;
    border-radius: 0;
    margin-bottom: 60px;
    margin-top: -20px;
}

#signupAd:hover {
    cursor: pointer;
}

#signupButton {
    border-width: 2px;
    border-style: solid;
    border-color: #E1C87F;
}

#signupButton:hover {
    border-width: 3px;
    cursor: pointer;
}

.btn-place, .btn-add {
    border-width: 2px;
    border-style: solid;
}

.btn-place {
    border: 2px solid #F17432;
    background: #F17432;
    border-radius: 0px;
    letter-spacing: 0.5px;
    font-weight: 300px;
}

.btn-add {
    border-color: #62B5B5;
}

.btn-place, .btn-add {
    font-size: 15px;
}

.btn-add:hover, .btn-place:hover {
    border-width: 3px;
    cursor: pointer;
}

a.tab-hover:hover {
    margin-right: 9px;
    margin-left: 9px;
}

img.rotating-icon {
    margin-right: 10px;
    animation: rotating-icon-spin infinite 2s linear;
}

@keyframes rotating-icon-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@-webkit-keyframes rotating-icon-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@media only screen and (max-width: 896px) {
    img.rotating-icon {
        margin-left: 10px;
    }
}

@media only screen and (min-width: 896px) {
    img.rotating-icon {
        margin-left: 15px;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}
