#profile-content {
    height: auto;

    background-color: white;
    min-height: calc(100vh - 100px);
}

@media only screen and (min-width: 1300px) {
    #profile-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #profile-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #profile-content {
        width: calc(100% - 50px);
    }
}

@media only screen and (max-width: 1300px) {
    #profile-wrapper {
        max-width: 90%;
    }
}

@media only screen and (min-width: 1300px) {
    #profile-wrapper {
        max-width: 100%;
    }
}

/* Class names for new user profile page */

.profile-container {
    display: flex;
    flex-direction: column;
    color: white;
    background-color: white;

    height: auto;

    position: relative;
}

.profile-container-profile {
    position: relative;
    display: flex;
    background-color: #1E2738;
    padding: 80px 70px 80px 120px;
    vertical-align: middle;
}

.profile-container-profile--bubble {
    flex: 1;
    background-color: #F17432;
    width: 620px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 7px white;
}

.profile-container-profile--bubble__letter {
    font-size: 160px;
    color: #1E2738;
    font-weight: 700;
}

.profile-container-profile--detail {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
}

.profile-container-profile--detail1 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.profile-container-profile--detail2 {
    font-size: 50px;
    letter-spacing: 2px;
    font-weight: 400;
}

.profile-container-profile--detail3 {
    margin-top: 15px;
    font-size: 14px;
    background-color: #9FC6CC;
    width: 37%;
    padding: 14px 14px 14px 18px;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.profile-container-profile--detail3_5 {
    color: #1E2738;
}

.profile-container-profile--detail4 {
    font-size: 30px;
}

.profile-container-profile--detail5 {
    font-size: 30px;
}

.profile-container-resps {
    padding: 0 15px;
    width: 1100px;
    margin: auto;
}

/* Class names for new profile questions */

.profile-question-container {
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0 40px;
    border-bottom: solid 1.5px #9FC6CC;
}

.profile-question-sidebar {
    display: flex;
    justify-content: space-between;
    flex: 1;
    color: #9FC6CC;
}

.profile-question-category {
    flex: 10;
    font-weight: 700;
    font-size: 20px;
}


.profile-question-toggler {
    flex: 1;
    background-color: #9FC6CC;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 21px;
    margin: 0 auto;
    position: relative;
}

.profile-question-toggler-sign {
    text-align: center;
    transform: translate(0, -4.5px);
    font-size: 20px;
}

.profile-question-responses {
    flex: 3;
    font-style: normal !important;
    letter-spacing: 1px;
    color: #1E2738;
    margin-left: 30px;
}
