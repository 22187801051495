.radio-question-wrapper {
    padding-bottom: 10px;
}

.radio-question-middle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    margin-top: 10px;
    background-color: #684CB5;

    padding-left: 5px;
    padding-right: 5px;
}

.radio-question-inner-wrapper {
    padding-top: 25px;
}
