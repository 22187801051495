.nav-menu-item.header.messaging {
    padding-left: 20px;
}

.nav-menu-item.account-type {
    margin-left: 15px;
}

#messenger-dropdown-icon {
    margin-left: 20px;

    width: 25px;
    height: 25px;
}
