#settings-content {
    height: auto;

    padding-bottom: 0px;
    background-color: rgb(238, 243, 248);

    min-height: calc(100vh - 100px);
}

@media only screen and (min-width: 1300px) {
    #settings-content {
        max-width: 1000px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #settings-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #settings-content {
        width: calc(100% - 50px);
    }
}

#settings {
    display: flex;
    height: 100%;
}

@media only screen and (min-width: 896px) {
    #settings {
        padding-top: 0px;
    }
}

#email-input {
    width: 275px;
}

#zipcode-input {
  width: 140px;
}

.settings-password-input {
    margin-top: 10px;
}

@media only screen and (max-width: 896px) {
    .settings-password-input {
        width: 100% !important;
    }

    #accountManagement {
        display: none;
    }
}

@media only screen and (min-width: 896px) {
    .settings-password-input {
        width: 100% !important;
    }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #304ffe;
}

input:focus + .slider {
  box-shadow: 0 0 1px #304ffe;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.indexRow {
    padding: 0.75rem 1.5rem;
    border: none !important;
    margin: 0 0 0.25rem 0;
}

.indexRow.create-account {
    padding-top: 1.5rem;
}

.indexRow:hover {
    cursor: pointer;
}

.indexRow h5 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
}

.indexRow.on h5 {
    color: #F17432;
}

.accountManagementListWrapper {
    width: 20%;
    min-width: 18rem;
    min-height: 100%;
    background-color: #1E2738;
}

.accountManagementList {
    list-style-type: none;
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.promptCreateFriendProfile {
    padding-top: 15em;
    padding-left: 30em;
}

.promptCreateDatingProfile {
    padding-top: 15em;
    padding-left: 32.5em;
}

.promptCreateCollaborationProfile {
    padding-top: 15em;
    padding-left: 30em;
}

.settingsContent {
    min-height: 100%;
    width: 100%;
    background-color: white;
}

@media only screen and (min-width: 330px) and (max-width: 896px) {
    .settingsContent {
        width: 100%;
    }
}

@media only screen and (min-width: 896px) and (max-width: 1300px) {
    .settingsContent {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}

@media only screen and (min-width: 1300px) {
    .settingsContent {
        padding-left: 0rem;
    }
}

.account-settings-header {
    text-align: center;
    background-color: #9FC6CC;
    padding: 20px;
}

.settingsContent .settingsTop {
  display: flex;
}

.settingsContent .settingsBottom {
  display: flex;
  justify-content: center;
  margin-right: 24rem;
  margin-top: 4rem;
  color: black !important;
}

.settingsContent .miscSettings {
  width: 50%;
}

.settingsContent .authSettings {
  width: 50%;
}

#create-button {
    border: 2px solid #A4F2F4;
    background-color: #262F6D;
    border-radius: 10px;

    min-height: 100%;
}

@media only screen and (max-width: 896px) {
    #create-button {
        width: 90%;

        padding-top: 0;
        padding-bottom: 10px;

        margin-top: 50px;
        min-height: unset;
    }
}

#create-button-title {
    font-size: 35px;
    font-weight: 300;
    text-align: center;
}

@media only screen and (max-width: 896px) {
    #create-button-title {
        font-size: 25px;
    }
}

#create-button-btn {
    margin: 0px auto 0;
    padding: 25px 30px;

    background-color: #F17432;
    border-radius: 0px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 18px;
}

.account-settings-save {
    align-self: flex-end;
    padding-top: 20px;
    padding-bottom: 40px;
}

@media only screen and (max-width: 896px) {
    #create-button-btn {
        margin: 40px auto 50px;
        padding: 20px 30px;
        font-size: 16px;
    }

    .account-settings-save {
        align-self: center;
        padding-bottom: 40px;
    }
}

.settings-create-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 400px;
}

.settings-pillar-img {
    width: 200px;
    height: 200px;
}
