.col {
    position: inherit;
    width:inherit;
    min-height: inherit;
    padding-right: inherit;
    padding-left: inherit;
    flex-basis: inherit;
    flex-grow: inherit;
    max-width: inherit;
}

.form-check-input {
    position: relative;
    margin-top: auto;
    margin-left: 10px;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active {
    background: none;
    border: none;
    outline: none;
    padding: 0 !important;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: rgba(0,0,0,0);
    border-color: rgba(0,0,0,0);
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
}

/*@media (max-width: 868px) {*/
    /*.navbar-expand-md .navbar-collapse {*/
        /*display: none !important;*/
        /*flex-basis: auto;*/
    /*}*/

    /*!*.collapse:not(.show) {*!*/
        /*!*display: none;*!*/
    /*!*}*!*/
/*}*/
