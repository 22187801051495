.cat-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto !important;
    margin-bottom: 0 !important;
    background-color: #684CB5;
}

@media only screen and (max-width: 700px) {
    .cat-header-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.cat-header-inner-wrapper {
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 20px;    
}

@media only screen and (min-width: 700px) {
    .cat-header-inner-wrapper {
        margin-left: auto;
    }
}

@media only screen and (max-width: 700px) {
    .cat-header-inner-wrapper {
        font-weight: bold;
    }

    .cat-header-dropdown-arrow {
        display: none;
    }
}

.cat-header-dropdown-arrow {
    margin-left: auto;
}

.cat-question-wrapper {
    width: auto !important;
    max-height: none !important;
    border-color: #9FC6CC;
}

.cat-question-wrapper.cat-content-hidden {
    max-height: 0 !important;
}
