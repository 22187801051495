#dating-content {
    height: auto;

    padding-top: 50px;
    padding-bottom: 100px;

    background-color: #404F8E;
    min-height: calc(100vh - 100px);
}

@media only screen and (min-width: 1300px) {
    #dating-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #dating-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #dating-content {
        width: calc(100% - 50px);
    }
}

@media only screen and (max-width: 896px) {
    #dating-content {
        padding-top: 0;
    }
}

.dating-option {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
}

.dating-option > img {
    width: 15px;
    height: 15px;

    margin-top: 5px;
    margin-right: 15px;
}

#dating-content-wrapper {
    border: 2px solid #A4F2F4;
    border-radius: 10px;

    background-color: #262F6D;
    margin-top: 75px;
}

@media only screen and (max-width: 896px) {
    #dating-content-wrapper {
        width: 90%;

        padding-top: 0;
        padding-bottom: 10px;

        min-height: unset;
    }

    #dating-content-wrapper.settings {
        margin-top: 50px;
    }

    #dating-content-header-wrapper {
        width: 105%;

        margin-left: -5px;
        margin-right: -5px;
    }
}

#dating-content-header {
    font-size: 35px;
    font-weight: 300;
    padding-bottom: 20px;
}

@media only screen and (max-width: 896px) {
    #dating-content-header {
        font-size: 25px;
        padding-bottom: 10px;
    }
}

#dating-content-search-wrapper {
    display: flex;
    align-items: center;

    flex-direction: row;
    padding-bottom: 20px;
}

@media only screen and (min-width: 896px) {
    #dating-content-search-wrapper.settings {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 896px) {
    #dating-content-search-wrapper {
        flex-direction: column;
        padding-bottom: 10px;
    }

    #dating-content-age-wrapper {
        width: 100%;
        display: flex;
        padding-top: 10px;
    }

    .dating-age-dropdown-low {
        margin: 0 5px;
    }

    .dating-age-dropdown-high {
        margin: 0 0 0 5px !important;
    }

    .dating-gender {
        width: 125px !important;
        margin-left: 5px !important;
        margin-top: 10px !important;
    }

    .dating-gender > select {
        width: 100% !important;
    }

    #dating-content-height-wrapper {
        display: flex;

        align-items: center;
    }
}

@media only screen and (max-width: 700px) {
    .dating-gender {
        width: 100% !important;
        margin-left: 0 !important;
    }
}

@media only screen and (min-width: 896px) {
    #dating-content-age-wrapper.hide-question {
        padding-left: 5px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
    #dating-content-search-label {
        margin-right: auto;
    }
}

#dating-content-height {
    width: 100px;

    margin-left: 25px;
}

@media only screen and (max-width: 896px) {
    #dating-content-height {
        width: 90px;

        margin: 0 0 0 10px;
    }

    #dating-photo-prompt {
        font-size: 18px;
    }

    #dating-photo-choose-prompt {
        margin-top: 10px;
    }

    #dating-photo-choose-prompt2 {
        margin-top: 15px;
    }
}

.profile-img.dating-content {
    max-width: 200px;
    max-height: 200px;
}

#dating-option-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 896px) {
    #dating-option-wrapper {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

#dating-content-alert-wrapper {
    border-width: 2px;
    border-style: solid;

    margin-top: 10px;
    margin-bottom: 15px;

    padding-top: 15px;
    padding-bottom: 15px;

    border-color: #EF585C;
    background-color: #EF585C;

    color: white;
    display: flex;

    align-items: center;
}

@media only screen and (min-width: 700px) {
    #dating-content-alert-wrapper {
        width: 100%;
    }
}

#dating-content-alert-wrapper.info {
    border-color: #5472CC;
    background-color: #5472CC;
}

#dating-content-alert-icon {
    min-width: 30px;
    max-width: 30px;
    height: 30px;

    font-weight: bold;
    text-align: center;

    border-radius: 50%;
    border-style: solid;
    border-color: white;

    margin-left: 15px;
    margin-right: 10px;
}

@media only screen and (max-width: 700px) {
    #dating-content-alert-icon {
        margin-left: 10px;
    }
}

#dating-content-alert-message {
    width: 500px;
}

@media only screen and (max-width: 700px) {
    #dating-content-alert-message {
        width: 180px;
    }
}

#dating-action-btns {
    padding-top: 20px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 896px) {
    #dating-action-btns {
        width: unset;

        padding-top: 10px;
        padding-bottom: 0;

        margin-bottom: 20px;
    }
}

#dating-action-go-back {
    padding-left: 40px;
    padding-right: 40px;

    border: none;
    background-color: #684CB5;
}

@media only screen and (max-width: 896px) {
    #dating-action-go-back {
        width: unset;

        padding-left: 10px;
        padding-right: 10px;

        padding-bottom: 0;
    }
}

#dating-action-continue {
    border: none;
    margin-left: auto;

    padding-left: 40px;
    padding-right: 40px;

    background-color: #684CB5;
}

@media only screen and (max-width: 896px) {
    #dating-action-continue {
        width: 110px;

        padding-left: 10px;
        padding-right: 10px;
    }

    #dating-action-continue.settings {
        font-size: 1rem;
        margin-right: auto;

        padding-left: 100px;
        padding-right: 100px;
    }
}

@media only screen and (max-width: 896px) {
    .dating-content-optional-break {
        display: none;
    }
}

.center-profile-image {
    display: flex;
    flex-direction: column; 
    text-align: center;
}
