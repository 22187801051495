#no-notifications-to-display {
    color: #000;
    margin-bottom: 0;
}

@media only screen and (max-width: 896px) {
    #no-notifications-to-display {
        margin-left: 5px;
    }
}

.nav-menu-item.notifications {
    padding: 10px 20px 10px 20px;
}

#pending-list {
    background: #FFF;
    max-height: 200px;
}

.tab.nav-img.pending-dropdown {
    width: 25px;
    height: 30px;

    margin-top: 5px;
    margin-left: 5px;
}

.tab.nav-img.no-pending-dropdown {
    width: 20px;
    height: 25px;
}
