.text-question-middle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #684CB5;

    padding-left: 10px;
    padding-right: 10px;
}

.text-question-inner-wrapper {
    padding-top: 25px;
    color: #1E2738;
    letter-spacing: 1px;
}
