@import url("https://fonts.googleapis.com/css?family=Ubuntu:300");

body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica', sans-serif;
    -webkit-text-size-adjust: 100%;
}

#easyrtcErrorDialog{
    display: none !important;
}

