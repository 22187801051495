#collab-content {
    height: auto;

    padding-top: 50px;
    padding-bottom: 100px;

    background-color: #404F8E;
    min-height: calc(100vh - 100px);
}

@media only screen and (min-width: 1300px) {
    #collab-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #collab-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #collab-content {
        width: calc(100% - 50px);
    }
}

@media only screen and (max-width: 896px) {
    #collab-content {
        padding-top: 0;
    }
}

#collab-content-wrapper {
    border: 2px solid #A4F2F4;
    background-color: #262F6D;
    border-radius: 10px;
}

#collab-content-header-wrapper {

}

@media only screen and (max-width: 896px) {
    #collab-content-wrapper {
        width: 90%;

        padding-top: 0;
        padding-bottom: 10px;

        min-height: unset;
    }

    #collab-content-wrapper.settings {
        margin-top: 50px;
    }
}

#collab-content-header-wrapper {
    font-size: 35px;
    font-weight: 300;
    text-align: center;
}

@media only screen and (max-width: 896px) {
    #collab-content-header-wrapper {
        width: 105%;
        font-size: 25px;

        margin-left: -5px;
        margin-right: -5px;
    }
}

#collab-action-btns {
    padding-top: 20px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 896px) {
    #collab-action-btns {
        width: unset;

        padding-top: 10px;
        padding-bottom: 0;

        margin-bottom: 20px;
    }
}

#collab-action-go-back {
    border: none;

    padding-left: 40px;
    padding-right: 40px;

    background-color: #684CB5;
}

@media only screen and (max-width: 896px) {
    #collab-action-go-back {
        width: 110px;

        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 430px) {
    #collab-action-go-back {
        margin-right: 10px;
    }
}

#collab-action-continue {
    border: none;
    margin-left: auto;

    padding-left: 40px;
    padding-right: 40px;

    background-color: #684CB5;
}

@media only screen and (max-width: 896px) {
    #collab-action-continue {
        width: 110px;

        padding-left: 10px;
        padding-right: 10px;
    }

    #collab-action-continue.settings {
        font-size: 1rem;
        margin-right: auto;

        padding-left: 100px;
        padding-right: 100px;
    }
}

#collab-content-alert-wrapper {
    border-width: 2px;
    border-style: solid;

    margin-top: 10px;
    margin-bottom: 15px;

    padding-top: 15px;
    padding-bottom: 15px;

    border-color: #EF585C;
    background-color: #EF585C;

    color: white;
    display: flex;

    align-items: center;
}

@media only screen and (min-width: 700px) {
    #collab-content-alert-wrapper {
        width: 100%;
    }
}

#collab-content-alert-wrapper.info {
    border-color: #5472CC;
    background-color: #5472CC;
}

#collab-content-alert-icon {
    min-width: 30px;
    max-width: 30px;
    height: 30px;

    font-weight: bold;
    text-align: center;

    border-radius: 50%;
    border-style: solid;
    border-color: white;

    margin-left: 15px;
    margin-right: 10px;
}

@media only screen and (max-width: 700px) {
    #collab-content-alert-icon {
        margin-left: 10px;
    }
}

#collab-content-alert-message {
    width: 400px;
}

@media only screen and (max-width: 700px) {
    #dating-content-alert-message {
        width: 180px;
    }
}
