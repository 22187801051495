#chat-content {
    height: auto;
    background-color: #404F8E;
}

@media only screen and (min-width: 1300px) {
    #chat-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #chat-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #chat-content {
        width: calc(100% - 50px);
    }
}

#easyrtcErrorDialog {
    display: none !important;
}

#videos {
    width: 100%;
    height: fit-content;

    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
}

@media only screen and (max-width: 896px) {
    #videos {
        padding-left: 20px;
        padding-right: 20px;
    }
}

#chatMain {
    flex-direction: row;
}

#videochat {
    height: 100%;

    padding: 5px;
    align-self: flex-start;

    background-color: #262F6D;
}

#call-btn {
    border: 2px solid #62B5B5;
    border-radius: 5px;
    padding: 10px;
}

@media only screen and (max-width: 896px) {
    #call-btn {
        width: 100%;
    }
}

#call-btn:hover {
    border-width: 3px;
}

#call-btn.no-video:hover {
    border-width: 2px;
    cursor: default;
}

@media only screen and (min-width: 896px) {
    #videochat {
        width: -moz-available;
        width: -webkit-fill-available;
    }
}

@media only screen and (max-width: 896px) {
    #videochat {
        width: 100%;
        height: calc(100vh - 100px);
    }
}

@media only screen and (max-width: 896px) and (max-height: 800px) {
    #videochat {
        height: auto;
    }
}

@media only screen and (max-width: 430px) and (max-height: 800px) {
    #videochat {
        height: auto;
    }
}

@media only screen and (max-width: 700px) {
    #videochat {
        padding-top: 50px;
    }
}

@media only screen and (min-width: 896px) {
    /* #mirrorVideo {
        width: 235px;
        height: 150px;
    } */
}

@media only screen and (max-width: 896px) {
    /* #mirrorVideo {
        width: 100%;
        height: 100%;
    } */
}

#mirrorDiv {
    border: 1px solid #A4F2F4;
}

@media only screen and (min-width: 896px) {
    #mirrorDiv {
        height: fit-content;
        width: fit-content;
    }
}

@media only screen and (max-width: 896px) {
    #mirrorDiv {
        height: 140px;
        width: 100px;
    }
}

#mirrorVideo,

#callerDiv {
    /* height: fit-content;
    width: 100%;

    border: 1px solid #A4F2F4; */
}

@media only screen and (max-width: 896px) {
    #callerDiv {
        height: 400px;
    }
}

#messenger {
    height: 100%;

    width: 285px;
    min-width: 285px;

    overflow: hidden;
    background: #404F8E;

    padding-left: 20px;
}

@media only screen and (min-width: 896px) and (max-width: 1300px) {
    #messenger {
        width: 260px;
        min-width: 260px;
    }
}

@media only screen and (max-width: 896px) {
    #messenger {
        width: 100%;
        overflow-y: scroll;
    }

    #messenger::-webkit-scrollbar {
        width: 0 !important;
    }
}

#contact-container {
    background-color: #404F8E;
}

#conversation-title,
#conversation-none {
    color: white;
}

#conversation-title {
    font-size: 18px;
    font-weight: 600;

    margin-top: 35px;
    letter-spacing: 0.15em;
}

@media only screen and (max-width: 896px) {
    #conversation-title {
        margin-top: 20px;
    }
}

#conversation-none {
    margin-top: 0;
    font-size: 16px;
}

#conversation {
    height: 450px;
}


@media only screen and (max-width: 896px) {
    #conversation {
        height: 350px;
    }
}

#sendMessageArea {
    padding: 10px 20px 10px 0;
    height: 15%;
    width: 100%;
}

#sendButton,
#videoButton {
    border: 2px solid #62B5B5;
}

#sendButton:hover {
    border-width: 3px;
}

#videoButton {
    background-color: #262F6D;
}

#call-btn.no-video,
#videoButton.no-video {
    border-color: rgb(128, 128, 128);
    background-color: rgb(128, 128, 128);
}

.message-author {
    color: white;
    font-size: 16px;
}

.message {
    padding: 10px;
    width: fit-content;

    border-radius: 4px;
    margin: 5px 5px 15px 0;
}

@media only screen and (min-width: 896px) {
    .message {
        max-width: 190px;
    }
}

@media only screen and (max-width: 896px) {
    .message {
        max-width: calc(100% - 10px);
    }
}

.message-self {
    background-color: #4CCCC9;
    color: black;
    overflow-wrap:break-word;
    word-wrap:break-word;
}

.message-self a {
    text-decoration: underline;
}

.message-other {
    background-color: #7B5BD4;
    color: white;
    overflow-wrap:break-word;
    word-wrap:break-word;
}

.message-other a {
    text-decoration: underline;
}

#contacts, #talks{
    margin: 10px 0;
}

#contacts.scrollable-container {
    border-top: 2px solid #262F6D;
}

#talks.scrollable-container {
    border-top: 2px solid #262F6D;
}

#message-container.scrollable-container {
    height: calc(100% - 25px);
    margin-top: 20px;
}

#contact-wrapper {
    width: 100%;
}

.chat-list-empty {
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 15px;
    font-size: 17px;
}

@media only screen and (min-width: 896px) {
    #chat-page {
        height: 750px;
        min-height: calc(100vh - 100px);
    }
}

.scrollable-container {
    width: 100%;
    overflow-x: hidden;
    flex-direction: column;
}

.scrollable-container::-webkit-scrollbar {
    width: 15px;
    background-color: #5062A3;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background-color: #0D184C;
}

#pendingTalks{
    padding-left: 10px;
    display: inline-flex;
    flex-direction: column;
}

#pendingIncoming{}

#pendingOutgoing{}

.chat-list-container {
    height: 100%;

    width: 285px;
    min-width: 285px;
}

@media only screen and (min-width: 896px) and (max-width: 1300px) {
    .chat-list-container {
        width: 260px;
        min-width: 260px;
    }
}

@media only screen and (max-width: 896px) {
    .chat-list-container {
        width: 100%;
    }
}

.list-navigator {
    display: flex;
    flex-direction: column;

    margin-top: 35px;
    margin-left: 20px;
}

@media only screen and (max-width: 896px) {
    .list-navigator {
        margin-top: 20px;
    }
}

.list-navigator-tab {
    color: white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.15em;

    width: fit-content;
    cursor: pointer;

    padding-bottom: 10px;
}

.list-navigator-tab.selected {
    color: #A4F2F4;
}

.contact-name {
    font-size: 23px;
    text-align: center;

    margin-top: 0;
    margin-bottom: 5px;

    color: white;
    cursor: pointer;

    padding: 5px;
    border-radius: 5px;
    border: 2px solid #62B5B5;
}

.contact-name:hover {
    border-width: 3px;

    padding-top: 4px;
    padding-bottom: 4px;
}

.login-status {
    margin: 14px 0 0 0 !important;
}

.clickable-star {
    width: 24px;
    height: 24px;

    margin-top: 8px;
    cursor: pointer;
}

#messages-btn {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
}


#contacts-btn {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.overlay-controls {
    z-index: 1;
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 5px;
    height: 0;
    background: rgba(255,255,255, .9);
}

.contact-card {
    width: 100%;
    border-bottom: 1px solid #262F6D;
    padding: 15px 5px !important;
}

.contact-card.no-border {
    border-bottom: none;
}

.contact-card.unread {
    background-color: #677BBF;
}

.contact-info {
    padding-left: 10px;
}

#search-contacts {
    border-color: white;
    border-width: 1px;

    padding-left: 15px;
    padding-right: 15px;

    margin: 20px;
    width: calc(100% - 40px);

    color: #262F6D;
    font-size: 18px;
}

#search-contacts::placeholder {
    color: #262F6D;
}

#contact-statuses {
    padding-left: 20px;
    display: flex;

    cursor: pointer;
}

#contact-statuses > div {
    letter-spacing: 0.1em;
}

div.contact-status-divider {
    background-color: #E5C673;
    width: 2px;

    margin-left: 10px;
    margin-right: 10px;
}

.post-call {
    background-color: #9FC6CC;

    max-width: unset;
    width: 350px;

    height: auto;
    top: 100px;
}

@media only screen and (max-width: 896px) {
    .post-call {
        width: calc(100% - 60px);

        margin-left: 30px;
        margin-right: 30px;
    }
}

.post-call .modal-content {
    height: 100%;

    margin-top: 15px;
    margin-right: 15px;

    border: none;
    background-color: unset;
}

.post-call h1 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

@media only screen and (max-width: 896px) {
    .post-call h1 {
        margin-top: 0;
        margin-bottom: 20px;
    }
}


#close-modal {
    cursor: pointer;
    margin: 10px 15px 10px auto;
}

.post-call-options {
    display: flex;
    justify-content: space-around;

    padding: 0 50px 50px;
}

@media only screen and (max-width: 896px) {
    .post-call-options {
        padding-bottom: 10px;

        align-items: center;
        flex-direction: column;
    }
}

.post-call-options .btn {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

@media only screen and (max-width: 896px) {
    .post-call-options .btn {
        width: 225px;
        margin-bottom: 20px;
    }
}

.post-fill {
    /* background: blue !important; */
    border: none !important;
}

#post-option {
    border: 1px solid black;
    margin-bottom: 10px;
    font-size: 14px;
    width: 220px !important;
}

#message-delete-note {
    width: 250px;
    font-size: 14px;

    padding: 10px 20px 10px 0;
}

.trash-button {
    margin-left: 20px;
    padding-bottom: 5px;
}

.trash-button-svg {
    fill: white;
}

.trash-button:hover {
    cursor: pointer;
}

.trash-button:hover > svg {
    fill: red;
}