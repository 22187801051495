/*Add your main/general CSS styling here*/
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: normal;
    src: local('Ubuntu Regluar'), url('ubuntu-v12-latin-regular.woff') format('woff');
}

html {
    min-height: 100%;

    /* background-image: url("../images/background-tile/Border.png");
 */
    background-color: rgb(238, 243, 248);
    /* overscroll-behavior: none; */
}

/* @media (-webkit-min-device-pixel-ratio: 2),
(min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    html {
        background-size: 7.5%;
        background-position: 17.5% 0;
        background-image: url("../images/background-tile/Border.png");
    }
}

@media only screen and (max-width: 1400px) {
    html {
        background-size: 6%;
        background-position: 5.6% 0;
        background-image: url("../images/background-tile/Border.png");
    }
}

@media only screen and (max-width: 1200px) {
    html {
        background-size: 8%;
        background-position: 76.5% 0;
        background-image: url("../images/background-tile/Border.png");
    }
}

@media only screen and (max-width: 1000px) {
    html {
        background-size: 7.81%;
        background-position: 41.8% 0;
        background-image: url("../images/background-tile/Border.png");
    }
}

@media only screen and (max-width: 430px) {
    html {
        background-size: 10.7%;
        background-position: 33.3% 0;
        background-image: url("../images/background-tile/Border.png");
    }
} */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #757575;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bdbdbd;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #bdbdbd;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Helvetica', sans-serif;
    min-height: calc(100vh - 60px);
    background: transparent;
}

:root {
    --primary-color: #f7f7ff;
    --primary-text: #007FFF;
    --primary-darker: #BA254B;
}

#root {
    min-height: calc(100vh - 60px);
}

main {
    min-height: calc(100vh - 60px);
}

.main-font {
    font-family: sans-serif;
}

div, input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.w-fill {
    width: 100%;
}

.w-fit {
    width: fit-content;
}

.h-fill {
    height: 100%;
}

.page-wrapper {
}

.page-wrapper.no-ok {
    padding-top: 125px;
}

@media only screen and (max-width: 1400px) {
    .page-wrapper.no-ok {
        padding-top: 150px;
    }
}

@media only screen and (max-width: 750px) {
    .page-wrapper.no-ok {
        padding-top: 175px;
    }
}

@media only screen and (max-width: 530px) {
    .page-wrapper.no-ok {
        padding-top: 200px;
    }
}

@media only screen and (max-width: 400px) {
    .page-wrapper.no-ok {
        padding-top: 225px;
    }
}

.frame-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(100vh - 76px);
}

@media only screen and (min-width: 896px) {
    .frame-wrapper {
        margin-left: auto;
        margin-right: auto;

        max-width: 870px;
    }
}

@media only screen and (max-width: 896px) {
    .frame-wrapper {
        width: 100%;
    }
}

.mt-40 {
    margin-top: 40px;
}

.frame {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 30px; */
}

.row {
    display: flex;
    align-items: center;

    margin-left: inherit;
    margin-right: inherit;
}

.row-apart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.wrap {
    flex-wrap: wrap;
}

.col-center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.col {
    display: flex;
    flex-direction: column
}

h1, h2, h3, h4, h5 {
    font-family: inherit;
    margin: 10px 0;
    color: var(--primary-color);
    /* color: #A6A7B1; */
}

.label {
    color: var(--primary-color);
}

.shadow {
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.1) !important;
    box-shadow:  0 0 15px 0 rgba(0,0,0,0.1) !important;
}

.background-hover {
    -webkit-transition: background .35s ease;
    transition: background .35s ease;
}

.color-hover {
    -webkit-transition: color .35s ease;
    transition: color .35s ease;
}

.content-section {
    background: #f7f7ff;
    padding: 20px;
    width: 400px;
    max-width: 100%;
    position: relative;
}

a, a:hover {
    font: inherit;
    color: inherit;
    text-decoration: none;
}

.faint-text {
    color: #bdbdbd;
}

.weak-text {
    color: #757575;
}

.text {
    color: var(--primary-color);
}

.fs-14 {
    font-size: 14px;
}

.p-20 {
    padding: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}
.ml-20 {
    margin-left: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-35 {
    padding-left: 35px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.m-10 {
    margin: 10px;
}

.m-20 {
    margin: 20px;
}

.ml-10 {
    margin-left: 10px;
}
.mr-10 {
    margin-right: 10px;
}

.ml-auto {
    margin-left: auto;
}

.request-talk:hover {
    background-color: #262F6D;
}

.mr-auto {
    margin-right: auto;
}


.align-right {
    align-items: flex-end;
}

.center-self {
    margin-left: auto;
    margin-right: auto;
}

.nav-wrapper {
    display: flex;
    position: fixed;
    z-index: 1100;
    top: 0;
    height: 90px;
    /* padding: 0 0 0 20px; */
    transition: box-shadow .35s ease;
    background: rgb(238, 243, 248);
}

.nav-wrapper.no-ok {
    top: 50px;
}

@media only screen and (max-width: 1400px) {
    .nav-wrapper.no-ok {
        top: 75px;
    }
}

@media only screen and (max-width: 750px) {
    .nav-wrapper.no-ok {
        top: 100px;
    }
}

@media only screen and (max-width: 530px) {
    .nav-wrapper.no-ok {
        top: 125px;
    }
}

@media only screen and (max-width: 400px) {
    .nav-wrapper.no-ok {
        top: 150px;
    }
}

.strap-nav {
    background: rgb(73, 73, 73);
}

#nav-title {
    position: absolute;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    top: 0;
}

.nav-title {
    /*color: var(--primary-color);*/
    color: #f7f7ff;
    margin: 5px 0;
    font-size: 40px;
}

.nav-inner {
    position: relative;
}

.tab.nav-img {
    margin-left: 10px;
    margin-right: 10px;
}

.tab, .tab:hover {
    cursor: pointer;
    color: var(--primary-color);
    -webkit-transition: color .35s ease;
    transition: color .35s ease;
    /* margin: 10px; */
}

.tab:hover {
    color: black;
}

.section-tab {
    margin-top: 0;

    margin-left: 20px;
    margin-right: 20px;

    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 800;
    letter-spacing: 3px;
}

.selected {
    color: #F17432 !important;
}

.bottom {
    bottom: 0;
    top: initial;
}

.rel {
    position: relative;
}

/*.tab:hover {*/
/*color: #707070;*/
/*}*/

.link {
    -webkit-transition: color .35s ease;
    transition: color .35s ease;
    color: #bdbdbd;
    width: fit-content;
}

.link:hover {
    color: #757575;
}

.link-primary {
    color: var(--primary-color);
}

.note {
    color: #bdbdbd;
}

.page-divider {
    border-left: 2px solid var(--primary-color);
    height: 80%;
    margin: 0 10px;
}

.center-text {
    text-align: center;
}

.nav-divider {
    border-left: 2px solid #606060;
    height: 20px;
    margin: auto 5px;
}

.primary {
    color: var(--primary-color)
}

.max-fill {
    max-width: 100%;
}

/*.custom-checkbox {*/
/*line-height: 24px;*/
/*}*/

.white-text {
    color: white;
}

.feature {
    width: 300px;
    margin: 30px;
}

.landing-img-wrapper {
    height: 200px;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}

.landing-img {
    width: 100%;
}

.flex-1 {
    flex: 1;
}

.split-page {
    max-width: 100%;
    margin: 0 10px;
    overflow: auto;
}

@media only screen and (min-width: 896px) {
    .split-page {
        min-width: 400px;
    }
}

.filter-check:hover {
    cursor: pointer;
}

.no-select, .not-selected {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #1E2738;
}

.not-selected {
    color: #A6A7B1;
}

.filter-form.form-group {
    margin-top: 5px;
}

.position-relative.form-group {
    margin-left: 10%;
    margin-bottom: 50px;
    line-height: 25px;
}

#travel-toggle:hover {
    cursor: pointer;
}

.miles-dropdown > select {
    padding-right: 5px;
    width: 60px !important;
    min-width: unset !important;
}

@media only screen and (max-width: 896px) {
    .friend-dropdown, .age-dropdown > .flat-select {
        min-width: 70px;
    }
}

.friend-dropdown {
    background-color: #1E2738;
    padding-left: 15px;
    font-size: 24px;
}

.friend-dropdown > select {
    color: white;
    background-color: transparent;
}

.friend-dropdown > svg {
    fill: white;
}

.dating-dropdown > select {
    color: white;
    border-color: #62B5B5;
    background-color: transparent;
}

.dating-dropdown > svg {
    fill: #62B5B5;
}

.filter-dropdown {
    width: 200px;
}

.filter-dropdown > select {
    width: 200px;
    color: white;
    border-color: #62B5B5;
    background-color: transparent;
}

.filter-dropdown > svg {
    fill: #62B5B5;
}

#filter-zipcode::placeholder {
    color: white;
}

.file-upload-wrapper {
    border: 2px solid #A4F2F4;
    border-radius: 5px;

    background-color: #262F6D;
    margin: 0 10px 0 0;
    padding: 5px 10px;
}

.file-upload-wrapper:hover {
    cursor: pointer;
}

.file-upload-wrapper > input[type="file"] {
    display: none;
}

.fit-divider {
    height: 100%;
    margin: 0 5px;
    border-right: 1px solid var(--primary-color);
}

.page-title {
    margin-left: 80px;
    margin-right: 80px;
}

.subscribe-link {
    color: rgb(122, 203, 230);
    font-size: .8rem;
    text-decoration: underline;
}

.subscribe-link:hover {
    color: rgb(122, 203, 230);
    font-size: .9rem;
    text-decoration: underline;
}

.certify {
    color: white;
    margin-bottom: 1rem;
    margin-top: .5rem;
}

.certify .form-check-input {
    margin-left: 0 !important;
}

.certify a {
    color: lightblue;
    text-decoration: underline;
}

.boxed {
    border: 2px solid var(--primary-color);
    padding: .4rem;
}

strong.subsection-start {
    color: black;
    font-size: 1.1rem;
}

.policy-link {
    color: rgb(66, 66, 66);
    text-decoration: underline;
}

.policy-link:hover {
    color: lightgrey;
    text-decoration: underline;
}

.policy-address {
    text-align: center;
}

.policy-address p {
    margin: 0
}

.page-intro {
    padding: 1.5rem 20% 4rem 20%;
    color: white;
}

.page-intro h1 {
    font-size: 2.75rem;
}

.page-intro p {
    margin-bottom: .4rem !important;
}

.section-header {
    height: 7rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
}

.section-header.la {
    background-color: #FF2025;
}

.section-header.sf {
    background-color: #FF9966;
}

.section-header.ny {
    background-color: #00D96C;
}

.section-header.tky {
    background-color: #6F00FF
}

.section-header h1 {
    margin: 0 !important;
    padding: 0 !important;
}

.section-body {
    padding: 2.5rem 20% 4rem 20%;
    color: white;
}

.section-body.key-takeaways ul {
    font-size: 1.2rem;
}

.section-body.key-takeaways li:first-of-type {
    margin-top: 0;
}

.section-body ul {
    padding: 0 20%;
}

.section-body li {
    margin: 1.5rem;
}

.justify-center {
    justify-content: center;
}

i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

i.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

i.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.table-of-contents {
    margin: 0 !important;
    width: 20rem !important;
    display: inline-block;
    font-weight: bolder;
}

.contents-list {
    width: 20rem !important;
    display: inline-block;
    text-align: left !important;
}

.contents-list ol {
    padding: .75rem !important;
}

.contents-list ol a {
    color: rgb(190, 221, 231);
    text-decoration: underline;
}

.anchor {
    margin-bottom: calc(60px - 5rem);
}

.dark {
    background-color: rgb(0,0,0,.2);
}

.home-background {
    background-repeat: repeat-x;
}

.home-page-info strong {
    color: black;
}

.tile-background {}

.home-page .outline-box {
    border-color: #00D96C;
    border-width: 4px;
    box-shadow: 0.375rem 0.375rem 1rem 0 rgba(0, 0, 0, 0.5);
}

.home-page button {
    box-shadow: 0.375rem 0.375rem 1rem 0 rgba(0, 0, 0, 0.3);
}

.home-page button:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

    -webkit-transition: transform .25s ease-in-out;
    -moz-transition:transform .25s ease-in-out;
    -ms-transition:transform .25s ease-in-out;
}

.home-page h4 {
    padding: 0 1rem;
}

.questions .event-card {
    margin: 15px 0 0 0;
}

.questions .event-card.required {
    background-color: rgb(243, 198, 115);
}

.required-text {
    color: grey;
    font-size: .75rem;
}

.primary-wrapper.questions {
    min-height: 0px !important;
}

@media only screen and (max-width: 774px) {
    #flipped {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 896px) {
    .page-title {
        margin-left: 0 !important;
        font-size: 32px;
        text-align: center;
    }
}

@media only screen and (max-width: 430px) {
    .responsive-col {
        flex-direction: column-reverse !important;
    }

    .media-center {
        align-items: center;
    }

    .media-drift {
        margin: 20px auto 0 10px;
    }

    .section-body ul {
        padding: 0;
    }

    .section-body {
        padding: 2.5rem 15% 4rem;
    }
}
