.friend-content-page-one-header {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: 2px;
    color: #1E2738;
}

#friend-content-page-one-header-wrapper {
    padding-top: 20px;
}

#friend-content-student-wrapper {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

#friend-content-student-img {
    width: 15px;
    height: 15px;

    margin-top: 5px;
    margin-right: 15px;
}

#friend-content-general-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    margin-bottom: 0;
    background-color: #9FC6CC;
}

@media only screen and (max-width: 700px) {
    #friend-content-general-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#friend-content-general-header {
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 700px) {
    #friend-content-general-header {
        font-weight: bold;
    }
}

@media only screen and (min-width: 700px) {
    #friend-content-general-header {
        margin-left: auto;
    }
}

#friend-content-general-arrow {
    margin-left: auto;
}

@media only screen and (max-width: 700px) {
    #friend-content-general-arrow {
        display: none;
    }
}

#friend-content-general-question-wrapper {
    width: auto;
    max-height: none;
    border-color: #9FC6CC;
}

#friend-content-general-question-wrapper.friend-content-hidden {
    max-height: 0;
}

#about-me-friend-header-outer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    background-color: #9FC6CC;
}

#about-me-friend-header-inner-wrapper {
    color: white;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
}

@media only screen and (max-width: 700px) {
    #about-me-friend-header-outer-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }

    #about-me-friend-header-inner-wrapper {
        font-weight: bold;
    }
}

#about-me-friend-text-area {
    height: 150px;
}

@media only screen and (max-width: 700px) {
    #about-me-friend-text-area {
        height: 100px;
    }
}
