#login-content {
    height: auto;
    min-height: calc(105vh - 120px);

    padding: 100px -10px;
    position: relative;
    overflow: hidden;
/*
    animation: zooming 0.12s;
    animation-delay: 0.18s; */
}

.login-cover-background {
}

/* A fix for iPhone glitch */
/* Login page goes missing upon
loading but appears when zoomed in  */

/* Update: As of the new design,
there is no issue. Keep this fix
here in case we need it again. */
@keyframes zooming {
    0% {
        zoom: 170%;
    }

    100% {
        zoom: 100%;
    }
}

@media only screen and (min-width: 1300px) {
    #login-content {
        max-width: 1100px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #login-content {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 700px) {
    #login-content {
        width: calc(100% - 50px);
    }
}

#login-stage-wrapper {
    height: 100%;
    flex-direction: column;
}

#login-stage-header {
    justify-self: flex-start;
    align-self: flex-start;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 64px;
    margin-top: 95px;
    margin-bottom: 30px;
    padding: 10px 20px;
    transform: translate(-30%, -30%);
}

@media only screen and (max-width: 700px) {
    #login-stage-header {
        margin-top: 64px;
        text-align: center;
    }

    #login-stage-span {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    #login-stage-header {
        font-size: 64px;
    }
}

#login-stage-form-wrapper {
    border: none;
    background-color: none;
    border-radius: 0px;
    min-height: 250px;
    /* transform: translate(80px, 10px); */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.login-circle {
    background: url("../images/figures/Circle2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    position: absolute;
    text-align: center;
    bottom: -150px;
    right: 50px;
}

@media only screen and (max-width: 1200px) {
    #login-stage-header {
        transform: translate(0%, -30%);
    }
}

@media only screen and (max-width: 1100px) {
    #login-stage-form-wrapper {
        width: 90%;
        transform: translateX(4%);
    }

    #login-stage-wrapper {
        max-width: none;
        margin: 0 8%;
    }

    #login-stage-header {
        transform: translate(0%, 0%);
    }

    .login-circle {
        width: 200px;
        height: 200px;
        bottom: 0px;
        right: 0px;
    }
}


.login-submit-buttons {

}

.login-inputs {
    border: solid #1E2738 1px;
    height: 40px;
    padding-left: 10px;
    color: #424242;
    /* border-radius: 4px; */
    font: inherit;
    /* border: 2px solid var(--primary-color); */
    /*background: rgba(0,0,0,.05);*/
    margin: 10px 0;
    -webkit-appearance: none;
    -webkit-border-radius:0;
    border-radius:0;
}


#login-stage-forgot-password, #login-stage-signup-link {
    text-decoration: none !important;
    font-weight: bold;
}

@media only screen and (max-width: 936px) {
    #login-stage-form-wrapper {
        width: 70%;
        margin: 0;
        transform: translateX(0%);
    }

    #login-stage-wrapper {
        margin: 0;
    }

    #login-alert-wrapper {
        width:100% !important;
    }

    #login-stage-header {
        font-size: 50px;
        transform: translateX(10%);
    }

    .login-submit-buttons {
        align-self: flex-end;
    }

    #login-content {
        background-size: cover;
        min-height: calc(100vh - 120px);
    }

    .login-inputs {
        width: 100%;
        background-color: transparent;
    }

    .login-circle {
        width: 200px;
        height: 200px;
        bottom: -220px;
        right: -100px;
    }

    #account-fields-check-text a {
        text-decoration: none;
        font-weight: bold;
    }
}

@media only screen and (max-width: 420px) {
    #login-content {
        background-size: cover;
        background-position: -90px 0px;
    }

    #login-stage-header {
        font-size: 40px;
    }
}

#login-stage-form-wrapper #passwordInput {
    margin-bottom: 30px;
}

#login-stage-signup-wrapper {
    color: #1E2738;
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
}

@media only screen and (max-width: 700px) {
    #login-stage-signup-wrapper {
        margin-left: 0;
        margin-top: 10px;
    }
}

#login-stage-signup-link {
    color: #F17432;
    text-decoration: underline;
}

#login-stage-action-btns {
    width: 240px;
    align-items: flex-end;
}

@media only screen and (max-width: 700px) {
    #login-stage-action-btns {
        width: 100%;
        align-items: flex-end;
    }
}

#login-stage-action-btns #loginButton {
    border: none;
    border-radius: 0px;
    margin-left: auto;

    padding-left: 30px;
    padding-right: 30px;

    background-color: #F17432;
    font-size: 16px;
    letter-spacing: 1.7px;
    font-weight: 700;
}

#login-stage-action-btns #loginButton:hover {
    cursor: pointer;
    border-width: 3px;
}

@media only screen and (max-width: 700px) {
    #login-stage-action-btns #loginButton {
        /* padding-left: 80px;
        padding-right: 80px;

        margin-right: auto; */
        margin-bottom: 10px;
    }
}

#login-stage-forgot-password {
    color: #F17432;
    text-decoration: underline;
}

#tfa-stage-wrapper {
    margin-top: 145px;
    height: 100%;
}

@media only screen and (max-width: 700px) {
    #tfa-stage-wrapper {
        margin-top: 50px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    #tfa-stage-wrapper {
        margin-top: 100px;
    }
}

#tfa-stage-header-wrapper {
    border: 2px solid #A4F2F4;
    background-color: #262F6D;
    border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
    #tfa-stage-header-wrapper {
        width: 90%;
        min-height: unset;

        padding-top: 0;
        padding-bottom: 10px;
    }
}

#tfa-stage-upper-header {
    font-weight: 300;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    #tfa-stage-upper-header {
        font-size: 30px;
    }
}

#tfa-stage-lower-header {
    margin-top: 0;
    margin-bottom: 20px;

    font-weight: 300;
    text-align: center;

    font-size: 15px;
}

@media only screen and (max-width: 700px) {
    #tfa-stage-lower-header {
        margin-top: 10px;
        margin-bottom: 10px;

        font-size: 20px;
    }
}

#tfa-stage-login-btn {
    padding-top: 20px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 700px) {
    #tfa-stage-login-btn {
        padding-top: 10px;
        padding-bottom: 0;
    }
}

#tfa-stage-login-btn > div.btn {
    padding-left: 40px;
    padding-right: 40px;

    border: none;
    background-color: #684CB5;

    margin-left: auto;
}

@media only screen and (max-width: 700px) {
    #tfa-stage-login-btn > div.btn {
        padding-left: 10px;
        padding-right: 10px;

        width: 110px;

        margin-right: auto;
        margin-bottom: 10px;
    }
}

#login-alert-wrapper {
    margin-top: 20px;
    margin-bottom: 15px;

    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;

    letter-spacing: 1.5px;

    background-color: #F17432;

    color: white;
    display: flex;

    align-items: center;
}

#login-alert-wrapper.tfa {
    margin-top: 0;
}

#login-alert-icon {
    width: 30px;
    height: 30px;

    font-weight: bold;
    text-align: center;

    border-radius: 50%;
    border-style: solid;
    border-color: white;

    margin-left: 15px;
    margin-right: 10px;
}

@media only screen and (max-width: 700px) {
    #login-alert-icon {
        margin-left: 10px;
    }
}
