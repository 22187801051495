.Payment {
  color: #32325d
}

.Payment.paymentForm {
  box-sizing: content-box;
  min-width: 250px;
  /* max-width: 370px; */
  background-color: #fff;
  margin: 0px;
  box-shadow: 0 0 3px #cfd7df;
  padding: 15px;
  border-radius: 4px;
}


input.Payment, .StripeElement  {
  color: white;
  font-weight: 200;
  font-size: 16px;
  background-color: rgb(30, 39, 56);
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
}

input.Payment::placeholder {
  color: #aab7c4
}

input.Payment--invalid, .StripeElement--invalid {
  border-color: #fa755a;
}


/* input.Payment:focus, .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
*/

#payButton.Payment {
  display: block;
  width: 100%;
  height: 40px;
  background-color: #000;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

#payButton.Payment:active {
  background-color: #333;
}

#payButton.Payment:focus {
  outline: none;
}
