@media only screen and (max-width: 700px) {
    .mc-question-wrapper {
        margin-right: 0;
    }
}

.mc-question-middle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #684CB5;

    padding-left: 10px;
    padding-right: 10px;
}

.mc-question-inner-wrapper {
    letter-spacing: 1px;
    color: 1E2738;
    padding-top: 25px;
    padding-bottom: 10px;
    margin: 0 10px;
    display: inline-block;
}
