#home-content {
    background: rgb(238, 243, 248);
    overflow-x: hidden;
}

.login-home-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 1300px) {
    #home-content {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 1300px) {
    #home-content {
        max-width: calc(100% - 110px);
    }
}

@media only screen and (max-width: 500px) {
    #home-content {
        max-width: 330px;
    }
}

#intro-container {
    background-color: white;
    /* margin-top: 15px; */
    display: flex;
    flex-direction: column;
    /* padding-left: 10px;
    padding-right: 10px; */
}

.intro-container {
    margin-top: 20px;
    background-color: white;
    display: flex;
}

.intro-container-text {
    background-color: #1E2738;
    font-size: 2.5em;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 40px;
    justify-content: center;
    text-align: left;

}

.intro-container-text--1 {
    color: #9FC6CC;
    font-size: 1.8em;
    margin-bottom: 16px;
}

.intro-container-text--2 {
    font-size: 1.2em;
}

.intro-container-text--3 {
    font-size: 1.2em;
    margin-bottom: 16px;
    white-space: nowrap;
}

.intro-container-text--4 {
    color: #F17432;
    font-size: 1.3em;
}

.intro-container-text--5 {
    color: #F17432;
    font-size: 1.3em;
}

.intro-container-text--6 {
    font-size: 1em;
    white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
    .intro-container-text {
        font-size: 1.8em;
        padding-left: 40px;
        padding-right: 25px;
    }
}

@media only screen and (max-width: 850px) {
    .intro-container-text {
        font-size: 1.65em;
        padding-left: 25px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 750px) {
    .intro-container-text {
        font-size: 1.3em;
        padding-left: 25px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .intro-container-text {
        font-size: 1.1em;
        padding-left: 17px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .intro-container-text {
        font-size: 0.9em;
        padding-left: 15px;
        padding-right: 15px;
    }
    .intro-container-text--1, .intro-container-text--3 {
        margin-bottom: 10px;
    }
}

.phones-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.phones-frame-img {
    width: 60%;
    transform: scale(1.5);
}

.home-page h4 {
    color: #1E2738;
}

#intro-welcome {
    padding-bottom: 10px;

    color: #A4F2F4;
    font-weight: bold;

    font-size: 25px;
    letter-spacing: 5px;
}

@media only screen and (max-width: 896px) {
    #intro-welcome {
        font-size: 22px;
        line-height: 1.5;
        padding-bottom: 0px;
    }
}

#intro-blurb {
    padding-bottom: 20px;
    font-weight: 100;
    line-height: 1.5;
    font-size: 27px;
}

@media only screen and (max-width: 896px) {
    #intro-blurb {
        font-size: 20px;
    }
}

#intro-event-link-wrapper {
    padding-bottom: 30px;
}

#intro-event-link-content {
    color: #E1C87F;
}

#home-prompt-outer-wrapper {
    position: relative;
    background-color: #1E2738;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    /* margin-bottom: 15px; */
}

#home-prompt-outer-wrapper a {
    color: #A4F2F4;
    font-weight: 500;
}

#home-prompt-inner-wrapper {
    margin-left: 25%;
    margin-right: 25%;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 30px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 896px) {
    #home-prompt-inner-wrapper {
        margin-left: 5%;
        margin-right: 5%;
    }
}

#home-prompt-content {
    width: 65%;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-weight: 100;
    text-align: center;
    color: white;
    line-height: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: 400;
}

bold {
    color: #F17432;
    font-weight: bold;
    font-size: 18px;
}

#home-prompt-content > span {
    font-size: 20px;
}

@media only screen and (max-width: 896px) {
    #home-prompt-content {
        font-size: 16px;
        margin-bottom: 0px;
        width: 85%;
    }

    #home-prompt-content > span {
        font-size: 18px;
    }

}

#home-page-no-got-login {
    height: 100vh;
}

#home-content-logged-in {
    max-width: 1200px;
    background: rgb(238, 243, 248);
    height: 100% !important;
    padding-top: 5vh;
}

@media only screen and (min-width: 700px) and (min-height: 700px) {
    #home-content-logged-in {
        height: calc(100vh - 100px);
    }
}

@media only screen and (min-width: 896px) and (max-height: 800px) {
    #home-content-logged-in {
        height: 100vh;
    }
}

@media only screen and (min-width: 1300px) {
    #home-content-logged-in {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 1300px) {
    #home-content-logged-in {
        max-width: calc(100% - 130px);
    }
}

@media only screen and (max-width: 400px) {
    #home-content-logged-in {
        max-width: 300px;
    }
}

#what {
    width: 100%;
    position: absolute;
    text-align: center;
    margin-top: 20px;
    color: #A4F2F4;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 5px;
}

#desc {
    color: white;
    font-size: 21px;
    letter-spacing: 2px;
    line-height: 30px;
    background: #1E2738;
    width: 45%;
    align-self: center;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    #what {
        position: relative;
        text-align: center;
        margin-top: 20px;
        color: #A4F2F4;
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 5px;
    }
    #desc {
        font-size: 14px;
        line-height: 30px;
    }
}


@media only screen and (max-width: 1100px) {
    #desc {
        font-size: 16px;
        width: 50%;
        line-height: 30px;
    }
}

@media only screen and (max-width: 800px) {
    #desc {
        /* font-size: 12px; */
    }
}

@media only screen and (max-width: 600px) {
    #desc {
        /* font-size: 12px; */
        width: 80%;
        line-height: 20px;
    }
}


#why {
    width: 100%;
    position: absolute;
    text-align: center;
    margin-top: -50px;
    color: #A4F2F4;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 5px;
    padding-bottom: 30px;
}

.center-text-top {
    text-align: center;
    padding-top: 25px;
}

.center-text-bottom {
    text-align: center;
    padding-bottom: 25px;
}

@media only screen and (max-width: 900px) {
    #why {
        position: relative;
        text-align: center;
        margin-top: 15px;
        color: #A4F2F4;
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 5px;
    }
}

@media only screen and (max-width: 896px) {
    #why {
        font-size: 22px;
    }
}

#home-pillar-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 100px;
    flex-direction: row;
    background-color: rgb(238, 243, 248);
}

.home-pillar-image {
    width: 40%;
}

#home-pillar-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 10px;
    flex-direction: row;
}

@media only screen and (max-width: 896px) {
    #home-pillar-link-wrapper {
        padding-top: 40px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    #home-pillar-link-wrapper, #home-pillar-image-wrapper {
        flex-direction: column;
    }
}

.home-pillar-link-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .home-pillar-link-inner-wrapper {
        flex-direction: column;
    }
}

.home-pillar-link {
    padding-left: 50px;
    padding-right: 50px;
}

@media only screen and (max-width: 896px) {
    .home-pillar-link {
        padding-bottom: 40px;
    }
}

.home-pillar-content-wrapper {
    height: 200px;
    width: 200px;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.home-pillar-content-wrapper.friendship {
    border-color: #F17432;
}

.home-pillar-content-wrapper.collaborate {
    border-color: #F17432;
}

.home-pillar-content-wrapper.relationship {
    border-color: #F17432;
}

/* .home-pillar-content-wrapper img {
    height: 75px;
    width: 75px;
}

@media only screen and (max-width: 896px) {
    .home-pillar-content-wrapper img {
        height: 68px;
        width: 68px;
    }
} */

.home-pillar-name {
    padding-top: 10px;
    padding-bottom: 10px;
}

.home-pillar-border {
    height: 3px;
    width: 95px;
}

.home-pillar-border.friendship {
    background-color: #F17432;
}

.home-pillar-border.collaborate {
    background-color: #F17432;
}

.home-pillar-border.relationship {
    background-color: #F17432;
}

.oval {
    width: 30%;
    z-index: 10;
    position: absolute;
    bottom: 60%;
    left: -10%;
}

.circle, .circle-2 {
    width: 15%;
    z-index: 10;
    position: absolute;
    top: -25%;
    left: 5%;
}

.rectangle, .rectangle-2 {
    width: 10%;
    z-index: 10;
    position: absolute;
    top: -17%;
    right: -6%;
}

.circle-2, .rectangle-2 {
    display: none;
}

@media only screen and (max-width: 1250px) {
    .oval {
        width: 35%;
        bottom: 65%;
        left: -15%;
    }

    .circle {
        width: 20%;
        top: -13%;
        left: -10%;
    }

    .rectangle {
        width: 15%;
        top: -6%;
        right: -6%;
    }
}

@media only screen and (max-width: 1000px) {
    .oval {
        width: 40%;
        bottom: 60%;
        left: -20%;
    }

    .circle {
        width: 20%;
        top: -15%;
        left: -10%;
    }

    .rectangle {
        width: 16%;
        top: -8%;
        right: -10%;
    }
}

@media only screen and (max-width: 900px) {
    .oval {
        width: 40%;
        bottom: 70%;
        left: -20%;
    }

    .circle {
        width: 20%;
        top: -12%;
        left: -10%;
    }

    .rectangle {
        width: 18%;
        top: -8%;
        right: -10%;
    }
}

@media only screen and (max-width: 850px) {
    .oval {
        width: 50%;
        bottom: 70%;
        left: -27%;
    }

    .circle {
        width: 25%;
        top: -12%;
        left: -13%;
    }

    .rectangle {
        width: 20%;
        top: -6%;
        right: -10%;
    }
}

@media only screen and (max-width: 800px) {
    .oval {
        width: 55%;
        bottom: 70%;
        left: -32%;
    }

    .circle {
        width: 30%;
        top: -14%;
        left: -15%;
    }

    .rectangle {
        width: 15%;
        top: -5%;
        right: -5%;
    }
}

@media only screen and (max-width: 750px) {
    .oval {
        width: 55%;
        bottom: 70%;
        left: -32%;
    }

    .circle {
        width: 30%;
        top: -12%;
        left: -15%;
    }

    .rectangle {
        width: 25%;
        top: -4%;
        right: -13%;
    }
}

@media only screen and (max-width: 600px) {
    .oval {
        width: 62%;
        top: -340%;
        left: -38%;
    }

    .circle {
        width: 30%;
        top: -5%;
        left: -20%;
    }

    .rectangle {
        width: 18%;
        top: -2%;
        right: -8%;
    }

    .circle-2 {
        display: unset;
        width: 40%;
        top: -230%;
        left: 80%;
    }

    .rectangle-2 {
        display: unset;
        width: 20%;
        top: -110%;
        left: -10%;
    }
}

@media only screen and (max-width: 500px) {
    .oval {
        width: 70%;
        top: -150%;
        left: -38%;
    }

    .circle {
        width: 30%;
        top: -3%;
        left: -15%;
    }

    .rectangle {
        height: 35%;
        top: -2%;
        right: -9%;
    }

    .circle-2 {
        display: unset;
        width: 50%;
        top: -100%;
        left: 75%;
    }

    .rectangle-2 {
        display: unset;
        width: 20%;
        top: -50%;
        left: -10%;
    }
}


#pillar-container {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 60px;
}

@media only screen and (max-width: 1250px) {
    #pillar-container {
        align-items: center;
        flex-direction: column;
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 1150px) {
    #pillar-container {
        padding-bottom: 15px;
    }
}

.pillar-inner-container {
    display: flex;
    width: 60%;
    padding-top: 75px; /* change when adding what info */
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 75px;
    /* z-index: 40; */
}

.pillar-inner-container:first-of-type{
    margin-left: 70px;
    padding-bottom: 30px;
}

.pillar-inner-container:last-of-type{
    margin-left: 75px;
}

@media only screen and (min-width: 700px) and (max-width: 1300px) {
    .pillar-inner-container {
        width: calc(100% - 100px);
        padding-top: 75px;
        margin-left: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 1000px) {
    .pillar-inner-container {
        padding-top: 0px;
        margin-bottom: 50px;
    }

    .pillar-inner-container:first-of-type {
        margin-left: 60px;
        padding-bottom: 50px;
    }

    .pillar-inner-container:last-of-type{
        margin-left: 75px;
    }
}

@media only screen and (max-width: 700px) {
    .pillar-inner-container {
        align-items: center;
        flex-direction: column;
        padding-top: 0px;
        margin-bottom:0px;
    }

    .pillar-inner-container:first-of-type {
        margin-left: 0px;
    }

    .pillar-inner-container:last-of-type {
        margin-left: 0px;
    }
}

#profileq {
    width: 100%;
    text-align: center;
    color: #E1C87F;
    font-weight: bold;
}

.convdesc {
    margin-top: 80px;
    color: #1E2738;
}

.relationdesc {
    margin-top: 90px;
    color: #1E2738;
}

.collabdesc {
    margin-top: 90px;
    color: #1E2738;
}

.eventsdesc {
    margin-top: 90px;
    color: #1E2738;
}

.hidden-pillar-inner {
    width: 95%;
    margin-top: -250px;
    text-align: center;
    margin-left: -10px;
}

.convdesc {
    margin-left: 15px;
}

.hidden-pillar-inner a {
    color: #F17432;
    text-decoration: underline;
}

.hover-layer {
    transition-duration: 1s;
    width: 90%;
}

.hover-layer.hovered {
    opacity: 0;
    visibility:hidden;
    /* display: none; */
}

.hover-layer.not-hovered {
    opacity: 1;
}

@media only screen and (max-width: 1200px) {
    .hidden-pillar-inner {
        width: 90%;
        margin-left: 0px;
        margin-top: 0px;
    }

    .hidden-pillar-inner:last-of-type {
        margin-top: -200px;
    }

    .convdesc {
        margin-top: 60px;
    }

    .eventsdesc {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 1100px) {
    .eventsdesc {
        margin-top: 40px;
    }

    .collabdesc {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1000px) {
    .eventsdesc {
        margin-top: 0px;
    }

    .collabdesc {
        margin-top: 0px;
    }

}

@media only screen and (max-width: 896px) {
    .hidden-pillar-inner {
        width: 90%;
        margin-left: 0px;
        margin-top: -250px;
    }

    .hidden-pillar-inner:last-of-type {
        margin-top: -135px;
    }

    .collabdesc {
        margin-top: 0px;
        margin-bottom: 40px;
    }

    .convdesc {
        margin-top: -50px;
    }

    .relationdesc {
        margin-top: -40px;
    }
}

@media only screen and (max-width: 700px) {
    .hidden-pillar-inner {
        margin-top: -320px;
        margin-left: 30px;
    }

    .eventsdesc {
        margin-top: -40px;
    }
}

@media only screen and (max-width: 650px) {
    .hidden-pillar-inner {
        margin-top: -235px;
        margin-left: 25px;
        padding-bottom: 50px;
    }
}

.plussign {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    margin-bottom:3px;
}

.click-for-more {
    text-transform: uppercase;
    color: #9FC6CC;
    font-weight: bold;
    /* visibility: hidden; */
    font-size: 14px;
}
/*
@media only screen and (max-width: 1250px) {
    .click-for-more {
        visibility: visible;
    }
} */

.pillar-content-wrapper {
    background: white;
    padding: 30px;
    width: 260px;
    height: 250px;

    display: flex;
    align-items: center;
    flex-direction: column;
}

/* .pillar-content-wrapper:hover {
    opacity: 0;
    visibility: hidden;
    transition-duration: 1s;
    z-index:-100;
} */

.pillar-content-wrapper:first-of-type {
    margin-left: -8%;
}

@media only screen and (max-width: 700px) {
    .pillar-content-wrapper {
        width: 280px;
        padding-bottom: 30px;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .pillar-content-wrapper:first-of-type {
        margin-left: 0px;
    }
}

/* .pillar-content-wrapper img {
    width: 75px;
    height: 75px;
}

@media only screen and (max-width: 896px) {
    .pillar-content-wrapper img {
        width: 68px;
        height: 68px;
    }
} */

.pillar-img {
    width: 75px;
    height: 75px;
}

@media only screen and (max-width: 896px) {
    .pillar-img {
        width: 68px;
        height: 68px;
    }
}

.pillar-content-wrapper h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #1E2738;
}

/* .pillar-content-wrapper div {
    height: 3px;
    width: 100px;
    background-color: #1E2738;
} */

.pillar-content-top {
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    color: #1E2738;
}

.pillar-content-top.relationship {
    width: 150%;
}

.pillar-content-bottom {
    font-size: 16px;
    text-align: center;
}

@media only screen and (max-width: 1100px) {
    .pillar-content-top.relationship {
        width: 100%;
    }
}

#features-list {
    display: flex;
    justify-content: center;
    background-color: #1E2738;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media only screen and (max-width: 896px) {
    #features-list {
        flex-direction: column;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.features-list-item {
    padding: 20px 40px;
    text-align: center;
}

@media only screen and (max-width: 896px) {
    .features-list-item {
        width: calc(100% - 30px);

        margin-left: 15px;
        margin-right: 15px;

        padding: 10px 15px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 896px) {
    .features-list-item {
        width: calc(100% - 100px);

        margin-left: 50px;
        margin-right: 50px;

        padding: 10px 30px;
    }
}

.features-list-item > h1 {
    font-weight: 300;
    font-size: 25px;

    margin-bottom: 30px;
}

@media only screen and (max-width: 896px) {
    .features-list-item > h1 {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 896px) {
    #face-to-face {
        width: 500px;
    }

    #no-ad-sharing {
        width: 315px;
    }
}

@media only screen and (max-width: 896px) {
    #face-to-face {
        margin-top: -10px;
    }

    #no-ad-sharing {
        margin-top: 40px;
    }
}
