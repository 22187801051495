.rollout-form-container {
    text-align: left;
    padding-top: 20px;
}

.rollout-form {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 896px) {
    .rollout-form {
        width: 200px;
        flex-direction: column;
    }
}

@media only screen and (min-width: 896px) {
    .rollout-form {
        align-items: flex-start;
    }
}

.rollout-form > .rollout-form-btn {
    border: 0;
    cursor: pointer;
    color: white !important;

    padding: 5px 15px;
    box-sizing: border-box;
}

.rollout-form > .rollout-form-btn:focus {
    outline: none;
}

.rollout-form-text-input {
    width: 0;
    padding: 0;
    color: #757575;

    border-width: 0;
    border-color: #999;
    border-style: solid;
    border-radius: 0 5px 5px 0;

    box-sizing: border-box;
    transition: width 0.2s ease-in-out;
}

.rollout-form-text-input.transitioning {
    padding: 5px;
}

@media only screen and (max-width: 896px) {
    .rollout-form-text-input.transitioning {
        margin-top: 5px;
        margin-bottom: 10px;

        border-radius: 5px;
    }
}

.rollout-form-text-input.expanded {
    padding: 5px;
    width: 200px;
}

@media only screen and (max-width: 896px) {
    .rollout-form-text-input.expanded {
        margin-top: 5px;
        margin-bottom: 10px;

        border-radius: 5px;
    }
}

.rollout-form-text-input.submitted {
    color: #32D296;
    border-color: #32D296;
    background-color: white;
}

.rollout-form-text-input.errored {
    color: #F0506E;
    border-color: #F0506E;
    background-color: white;
}

@media only screen and (min-width: 896px) {
    .rollout-form-text-input {
        margin-left: 5px;
    }
}

.rollout-form-text-input.user-reporting {
    resize: none;
    height: 80px;
}
